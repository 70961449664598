import { useState } from "react";
import { removeAuth, useAuth } from "../../auth";
import { Link } from "react-router-dom";

const EmployerHeader = () => {
  const { currentUser, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    localStorage.setItem("Forte", "");
    localStorage.removeItem("Forte");
    removeAuth();
    logout();
  };
  return (
    <header className="row">
      <nav className="navbar navbar-expand-md p-0">
        <div className="container-fluid ps-3 ps-md-4 pe-0">
          <div className="nav-brand">
            <img
              className="img-fluid"
              src="../../../media/images/logo-white.png"
              alt="forte"
            />
          </div>
          <div className="hdr-right d-flex">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link active" to="/profile/view">
                    Home 
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/employer/add/emploper-details"
                  >
                    Posting Jobs
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/posting-jobs">
                    Posting Jobs
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/explore-jobs">
                    Explore Jobs
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/applied-job">
                    Applied Jobs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/saved-jobs">
                    Saved Jobs
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className={`dropdown profile-menu ${isOpen ? "show" : ""}`}>
              <button
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded={isOpen ? "true" : "false"}
                onClick={toggleDropdown}
              >
                <div className="img-wrap">
                  <img
                    className="img-fluid"
                    src="../../../media/images/blank.png"
                    alt="Name"
                  />
                  {/* <Avatar
                        name={currentUser?.name}
                        maxInitials={2}
                        alt="User Avatar"
                      /> */}
                </div>
                <span>{currentUser?.name}</span>
              </button>

              <ul
                style={{ right: "0" }}
                className={`dropdown-menu dropdown-menu-end ${
                  isOpen ? "show" : ""
                }`}
                aria-labelledby="dropdownMenuButton1"
              >
                {/* <li>
                      <Link className="dropdown-item" to="#">
                        Change Password
                      </Link>
                    </li> */}
                <li
                  onClick={() => handleSignOut()}
                  style={{ cursor: "pointer" }}
                  className="dropdown-item "
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export { EmployerHeader };
