

export type PersonalDetail = {
  userId: any;
  categoryId: number;
  jobTitle: string;
  jobType:string;
  jobRole:string;
  experience:string;
  location:string;
  salary:string;
  hiringTimeline:string;
  hireCount:number;
  technology:string;
  benefits:string;
  qualification:string;
  workingHours:number;
  jobDescription:string;
  id?: any;
  topStrengths: string;
  topValues: string;
  Message: string;
  Code: any;
  country: string;
  currency: string;
  // state: string;
  // city: string;
  // pincode: string;
  //  resumeName: any;
  isSaved:any,
  isApplyed:any,
   
};
export let initialPersonalValues: PersonalDetail = {
  userId: "",
  jobTitle: "",
  jobType: "",
  jobRole: "",
  experience: "",
  location: "",
  salary: "",
  hiringTimeline: "",
  hireCount: 0,
  technology: "",
  benefits: "",
  qualification: "",
  workingHours: 0,
  jobDescription: "",
  topStrengths: "",
  topValues: "",
  Message: "",
  Code: "",
  id: "",
  country: "",
  currency: "",
  categoryId: 0,
  isSaved:"",
  isApplyed:"",
};

export type ExperienceDetail = {
  id?: any;
  currency: string;
  experienceYears: string;
  experienceMonths: string;
  annualSalary: string;
  resume: any;
  resumeName: any;
  document: any;
  profileStatus: string;
  workedorganisations: [
    {
      role: any;
      organisation: any;
      location: any;
      workType: any;
      startYear: any;
      startMonth: any;
      endYear?: string;
      endMonth?: string;
      isCurrentlyWorking: boolean;
    }
  ];
  technologies: [
    {
      name: any;
      workedFor: any;
    }
  ];
  referralInfo: [
    {
      name: any;
      email: any;
      contactNumber: any;
      organisation: any;
      years: any;
    }
  ];
};

export let initialExperienceValues: ExperienceDetail = {
  experienceYears: "",
  experienceMonths: "",
  currency: "",
  document: "",
  annualSalary: "",
  resume: "",
  resumeName: "",
  profileStatus: "",
  workedorganisations: [
    {
      isCurrentlyWorking: false,
      role: "",
      organisation: "",
      location: "",
      workType: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
    },
  ],
  technologies: [
    {
      name: "",
      workedFor: "",
    },
  ],
  referralInfo: [
    {
      name: "",
      email: "",
      contactNumber: "",
      organisation: "",
      years: "",
    },
  ],
};


