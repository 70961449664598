import { useEffect, useState } from "react";
import { EmployerHeader } from "./EmployerHeader";
import { getUserList } from "../profile/profile-form/core/requests";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "react-avatar";
import { getPostingJobsList } from "./core/request";
// import { deletePostingjobs } from "../employer-profile/employer-profile-form/core/requests";
import { Button, Modal } from "react-bootstrap";
import { deletePostingjobs } from "../posting-jobs/posting-jobs-form/core/requests";

const PostingJobsList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const [sort, setSort] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState<string[]>([]);
  const [jobRole, setJobRole] = useState<string[]>([]);
  const [qualification, setqualification] = useState("");
  const [experienceYearsMin, setExperienceYearsMin] = useState("");
  const [experienceYearsMax, setExperienceYearsMax] = useState("");
  const [expectedSalaryMin, setExpectedSalaryMin] = useState("");
  const [expectedSalaryMax, setExpectedSalaryMax] = useState("");
  const [technology, setTechnology] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState<string>("");

  // console.log("*************************************************")
  // console.log("selectedJobId", selectedJobId)
  // console.log("**************************************************")
  // console.log("*************************************************")
  // console.log("users", users)
  // console.log("users-----id", users.id)
  // console.log("**************************************************")
  // console.log("sort", sort);
  // console.log("jobTitle", jobTitle);
  // console.log("jobType", jobType);
  // console.log("jobRole", jobRole);
  // console.log("qualification ", qualification);
  // console.log("experienceYears", experienceYearsMin, experienceYearsMax);
  // console.log("expectedSalary", expectedSalaryMin, expectedSalaryMax);
  // console.log("technology", technology);
  const handleCheckboxChange = (setter: React.Dispatch<React.SetStateAction<string[]>>, value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setter((prev) => [...prev, value]);
    } else {
      setter((prev) => prev.filter((item) => item !== value));
    }
  };

  const filterData = {
    sort,
    jobTitle,
    jobType,
    jobRole,
    qualification: qualification.split(',').map(q => q.trim()),
    experienceYearsRange:
      experienceYearsMin || experienceYearsMax
        ? [experienceYearsMin, experienceYearsMax]
        : null,
    expectedSalaryRange:
      expectedSalaryMin || expectedSalaryMax
        ? [expectedSalaryMin, expectedSalaryMax]
        : null,
    technology: technology.split(',').map(t => t.trim()),
  };

  useEffect(() => {
    getPostingJobsList(filterData).then((res) => {
      setUsers(res?.data);
    });
  }, []);

  const clickApply = async (e: any) => {
    e.preventDefault();
    try {
      const filteredExperienceRange =
        experienceYearsMin || experienceYearsMax
          ? [experienceYearsMin, experienceYearsMax]
          : null;
      const filteredSalaryRange =
        expectedSalaryMin || expectedSalaryMax
          ? [expectedSalaryMin, expectedSalaryMax]
          : null;
      const filteredData = {
        ...filterData,
        experienceYearsRange: filteredExperienceRange,
        expectedSalaryRange: filteredSalaryRange,
      };

      const res = await getPostingJobsList(filteredData);
      setUsers(res?.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  const clickClear = () => {
    setSort("");
    setJobTitle("");
    setJobType([]);
    setJobRole([]);
    setTechnology("");
    setExperienceYearsMin("");
    setExperienceYearsMax("");
    setExpectedSalaryMin("");
    setExpectedSalaryMax("");
    setqualification("");
  };

  const handleDelete = async () => {
    if (selectedJobId !== null) {
      try {
        await deletePostingjobs(selectedJobId);
        const res = await getPostingJobsList(filterData);
        setUsers(res?.data);
      } catch (error) {
        console.error("Error deleting job posting:", error);
      }
      setShowModal(false);
      setSelectedJobId(null);
      navigate("/posting-jobs", { replace: true });
    }
  };
  const openModal = (id: number, title: string) => {
    setSelectedJobId(id);
    setSelectedJobTitle(title);
    setShowModal(true);
  };
  // const openModal = (id: number) => {
  //   setSelectedJobId(id);
  //   setShowModal(true);
  // };

  const closeModal = () => {
    setShowModal(false);
    setSelectedJobId(null);
  };

  return (
    <>
      <section className="container-fluid">
        <EmployerHeader />
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-2">
            <div className="container">
              <div className="row">
                <div className="col-5 col-md-6">
                  <h2 className="page-title mb-0">Find Matches</h2>
                  <h4 className="fw-normal mb-0">
                    Search Results {users?.length}
                  </h4>
                </div>
                <div className="col-6 col-md-4"></div>
                <div className="col-1 col-md-2">
                  <div className="sort-by" style={{ paddingLeft: "60px" }}>
                    <Link to="/employer/add/posting-jobs">
                      <button className="btn btn-asphalt" type="button">
                        Add
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row find-match-wrap mt-3">
                <div className="col-4 col-lg-4">
                  <div className="left-search-wrap">
                    <div className="form-wrap">
                      <form action="#" className="custom-form">
                        {/* Job Title */}
                        <div className="mb-3">
                          <label className="form-label">Job Title</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                          ></textarea>
                        </div>
                        {/* Job Type */}
                        <div className="mb-3">
                          <label className="form-label">Job Type</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              {["Onsite", "Hybrid", "Remote"].map((type) => (
                                <div className="col-6 col-md-4 form-check form-check-inline me-0" key={type}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`jobType-${type}`}
                                    value={type}
                                    checked={jobType.includes(type)}
                                    onChange={handleCheckboxChange(setJobType, type)}
                                  />
                                  <label className="form-check-label" htmlFor={`jobType-${type}`}>{type}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/* Job Role */}
                        <div className="mb-3">
                          <label className="form-label">Job Role</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              {["Full Time", "Contract", "Part Time"].map((role) => (
                                <div className="col-4 form-check form-check-inline me-0" key={role}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`jobRole-${role}`}
                                    value={role}
                                    checked={jobRole.includes(role)}
                                    onChange={handleCheckboxChange(setJobRole, role)}
                                  />
                                  <label className="form-check-label" htmlFor={`jobRole-${role}`}>{role}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/* Education */}
                        <div className="mb-3">
                          <label className="form-label">Education</label>
                          <input
                            type="text"
                            className="form-control"
                            value={qualification}
                            onChange={(e) => setqualification(e.target.value)}
                          />
                        </div>
                        {/* Experience */}
                        <div className="mb-3">
                          <label className="form-label">
                            Experience <small>(in years)</small>
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Min"
                              value={experienceYearsMin}
                              onChange={(e) => setExperienceYearsMin(e.target.value)}
                            />
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Max"
                              value={experienceYearsMax}
                              onChange={(e) => setExperienceYearsMax(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* Salary */}
                        <div className="mb-3">
                          <label className="form-label">Salary</label>
                          <div className="input-group two-input-ico">
                            <span className="input-group-text">$</span>
                            <input
                              type="number"
                              className="form-control ps-0"
                              placeholder="Min"
                              value={expectedSalaryMin}
                              onChange={(e) => setExpectedSalaryMin(e.target.value)}
                            />
                            <span className="input-group-text">$</span>
                            <input
                              type="number"
                              className="form-control ps-0"
                              placeholder="Max"
                              value={expectedSalaryMax}
                              onChange={(e) => setExpectedSalaryMax(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* Technology */}
                        <div className="mb-3">
                          <label className="form-label">Technologies</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={technology}
                            onChange={(e) => setTechnology(e.target.value)}
                          ></textarea>
                        </div>
                      </form>
                      <div className="btm-btn">
                        <button
                          className="btn btn-cancel"
                          type="button"
                          onClick={clickClear}
                        >
                          Clear All
                        </button>
                        <button
                          className="btn btn-asphalt"
                          type="submit"
                          onClick={clickApply}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* list */}
                <div className="col-8 col-lg-8 result-listing mt-4 mt-lg-0">
                  {users?.length > 0 ? (
                    <div className="listing-wrap">
                      {users?.map((item: any) => (
                        <div className="mb-3" key={item.id}>
                          <div className="candidate-snippet">
                            <div className="head">
                              <div className="avatar-encl">
                                {item?.profile ? (
                                  <div className="avatar-img">
                                    <img
                                      className="img-fluid"
                                      src={item?.profile}
                                      alt="Candidate Name"
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar-img">
                                    <Avatar
                                      className="img-fluid"
                                      name={item?.jobTitle}
                                    ></Avatar>
                                  </div>
                                )}
                                <div className="avatar-detail">
                                  <h5 className="mb-1">{item?.jobTitle}</h5>
                                  <p className="mb-0 fs-13 text-gray">
                                    {item?.skills?.map(
                                      (skill: any, index: any) => (
                                        <span key={index}>
                                          {skill.name}
                                          {index < item.skills.length - 1 &&
                                            ", "}
                                        </span>
                                      )
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="btn-encl">
                              <Link to={`/employer/edit/posting-jobs/${item.id}`}>
                                {/* <Link to="/employer/edit/posting-jobs"> */}
                                  <span className="material-symbols-outlined">
                                    stylus
                                  </span>
                                </Link>
                                <button
                                  className="btn circle-ico"
                                  type="button"
                                  onClick={() => openModal(item.id, item.jobTitle)}
                                >
                                  <span className="material-symbols-outlined">
                                    delete
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="cont">
                              <div className="row">
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Experience
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.experience} Years
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Salary</p>
                                  <p className="fs-13 mb-0">$ {item?.salary}</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Education
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.qualification}
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Technology
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.technology}
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Mode Of Work
                                  </p>
                                  <p className="fs-13 mb-0">{item?.jobType}</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Job Role
                                  </p>
                                  <p className="fs-13 mb-0">{item?.jobRole}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-info" role="alert">
                      No results found.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Modal */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this job posting?
          {selectedJobTitle && (
            <p>{selectedJobTitle}</p>
          )}
        </Modal.Body>
        {/* <Modal.Body>Are you sure you want to delete this job posting?</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End */}
    </>
  );
};

export { PostingJobsList };
