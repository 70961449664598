import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import { SplashScreen } from "../features/SplashScreen";
import { useAuth } from "../auth";
import ViewProfileInfo from "../modules/employer/ViewProfileInfo";
import { getUserProfile } from "../auth/core/_requests";
import React from "react";
import { EmployerOtpVerification } from "../auth/component/employer/EmployerOtpVerification";
import { AppliedJobs } from "../modules/profile/profile-form/AppliedJobs";
import { ExploreJobs } from "../modules/profile/profile-form/ExploreJobs";
import { PostingJobsList } from "../modules/employer/PostingJobsList";
import EmployerProfilePageNew from "../modules/posting-jobs/PostingJobsPage";
import { EmployeeJobsList } from "../modules/profile/profile-form/EmployeeJobsList";
// import { SavedJobs } from "../modules/profile/profile-form/SavedJobs";
import { EmployerDashboard } from "../modules/employer/EmployerDashboard";
import { SavedJobs } from "../modules/profile/profile-form/SavedJobs";

const PrivateRoutes = () => {
  const [data, setData] = useState<number>();
  const [getType, setGetType] = useState();
  const { currentUser } = useAuth();

  useEffect(() => {
    getUserProfile(currentUser?.userId).then((res) => {
      setData(res?.data?.Data?.profileStatus || []);
      const getType = res?.data?.Data?.categoryId;
      setGetType(getType);
    });
  }, []);

  const ProfilePage = lazy(() => import("../modules/profile/EmployeePage"));

  var Gotoo = "";

  if (Number(currentUser?.profileStatus) === 0) {
    var Gotoo = "/profile/add/personal-detail";
  }
  if (Number(currentUser?.profileStatus) === 1) {
    var Gotoo = "/profile/add/experience";
  }
  if (Number(currentUser?.profileStatus) === 1) {
    var Gotoo = "/profile/add/fresher";
  }
  if (Number(currentUser?.profileStatus) === 2) {
    var Gotoo = "/profile/add/resume";
  }
  if (Number(currentUser?.profileStatus) === 3) {
    var Gotoo = "/profile/add/visa";
  }
  if (Number(currentUser?.profileStatus) === 4) {
    var Gotoo = "/profile/add/skills";
  }
  if (Number(currentUser?.profileStatus) === 5) {
    var Gotoo = "/profile/add/education";
  }
  if (Number(currentUser?.profileStatus) === 6) {
    var Gotoo = "/profile/add/expectation";
  }
  if (Number(currentUser?.profileStatus) === 7) {
    var Gotoo = "/profile/add/social-media";
  }
  // if (Number(currentUser?.profileStatus) === 8) {
  //   var Gotoo = "/profile/add/photo";
  // }
  if (Number(currentUser?.profileStatus) === 8) {
    var Gotoo = "/profile/view";
  }
  // if (Number(currentUser?.profileStatus) === 10) {
  //   var Gotoo = "/dashboard";
  // }

  return (
    <Routes>
      <Route path="auth/*" element={<Navigate to={Gotoo} />} />

      <Route
        path="profile/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <ProfilePage />
          </Suspense>
        }
      />
      <Route
        path="dashboard/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <EmployerDashboard />
          </Suspense>
        }
      />
      <Route
        path="employee-profile/:id"
        element={
          <Suspense fallback={<SplashScreen />}>
            <ViewProfileInfo />
          </Suspense>
        }
      />
      <Route
        path="employer-otp-verification/"
        element={
          <Suspense>
            <EmployerOtpVerification />
          </Suspense>
        }
      />
      <Route
        path="applied-job/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <AppliedJobs />
          </Suspense>
        }
      />
      <Route
        path="explore-jobs/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <ExploreJobs />
          </Suspense>
        }
      />
      <Route
        path="saved-jobs/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <SavedJobs />
          </Suspense>
        }
      />
 
       {/* Employer Route */}
       <Route
        path="employer/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <EmployerProfilePageNew />
          </Suspense>
        }
      />
  {/* <Route
        path="/employer/add/posting-jobs*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <EmployerDetails />
          </Suspense>
        }
      /> */}
       <Route
        path="posting-jobs/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <PostingJobsList />
          </Suspense>
        }
      />
      {/* Employee Home Postingjobs List */}
      <Route
        path="employee-jobs/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <EmployeeJobsList />
          </Suspense>
        }
      />
       {/* End */}
    </Routes>
  );
};

export { PrivateRoutes };
