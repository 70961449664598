import React, { FC, useEffect, useState } from "react";
// import { Menu } from "./Menu";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddPostingJobs, getCountries, getStates, personalDetail } from "../core/requests";
import { useAuth, removeAuth } from "../../../../auth";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { PersonalDetail, initialPersonalValues as initialValues, } from "../core/model";
import { getUserProfile } from "../../../../auth/core/_requests";
import { AccessDenied } from "./AccessDenied";
// import Select from "react-select/dist/declarations/src/Select";
import Select, { MultiValue, GroupBase } from "react-select";

//PROFILE SCHEMA
const personalDetailSchema = Yup.object().shape({
  // categoryId: Yup.string().required(
  //   "Please select one of the following options: Fresher, Experienced, or Returning Mothers. Category selection is required."
  // ),
  // gender: Yup.string().required("Gender is required"),
  // institution: Yup.string()
  //   .matches(
  //     /^[^\d!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~]*$/,
  //     "Institution should contain only letters"
  //   )
  //   .max(50, "minimum 3 letters")
  //   .max(50, "Maximum 50 letters")
  //   .required("Institution is required"),
  // address: Yup.string().required("Address is required"),
  // aboutUser: Yup.string()
  //   .required("This field is required")
  //   .test(
  //     "word-count",
  //     "Maximum 100 words allowed",
  //     (value) => value.trim().split(/\s+/).length <= 100
  //   ),
  // country: Yup.string().required("Country is required"),
  // state: Yup.string().required("State is required"),
  // city: Yup.string()
  //   .required("City is required")
  //   .matches(/^[^\d]*$/, "Numeric values are not allowed"),
  // pincode: Yup.string()
  //   .required("Pincode is required")
  //   .matches(/^[0-9]+$/, "Pincode must contain only numeric characters"),
  // topStrengths: Yup.string()
  //   .required("Top Strengths is required")
  //   .matches(/^[^\d]*$/, "Numeric values are not allowed"),
  // topValues: Yup.string()
  //   .required("Top Values is required")
  //   .matches(/^[^\d]*$/, "Numeric values are not allowed"),

  // linkedInProfile: Yup.string()
  //   .matches(/^https:\/\/www\.linkedin\.[a-z]+\/.*$/, {
  //     message: "Invalid LinkedIn URL. Example: https://www.linkedin.com/",
  //     excludeEmptyString: true,
  //   })
  //   .nullable(),
});


const EmployerDetails= () => {
  //Get Profile Detail
  const [getStatus, setGetStatus] = useState<number>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [checkedModes, setCheckedModes] = useState<string[]>([]);
  const [checkedWorkType, setCheckedWorkType] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);

  const options = [
    { value: "Salesforce Administrator", label: "Salesforce Administrator" },
    {
      value: "Salesforce Business Analyst",
      label: "Salesforce Business Analyst",
    },
    { value: "Salesforce Developer", label: "Salesforce Developer" },
    {
      value: "Salesforce Functional Consultant",
      label: "Salesforce Functional Consultant",
    },
    {
      value: "Salesforce Platform Manager",
      label: "Salesforce Platform Manager",
    },
    {
      value: "Salesforce Solution Architect",
      label: "Salesforce Solution Architect",
    },
    {
      value: "Salesforce Technical Architect",
      label: "Salesforce Technical Architect",
    },
    {
      value: "Salesforce Projects Manager",
      label: "Salesforce Project Manager",
    },
    {
      value: "Senior Test Automation Engineer",
      label: "Senior Test Automation Engineer",
    },
    { value: "Senior ABAP Developer", label: "Senior ABAP Developer" },
    { value: "ServiceNow Engineer", label: "ServiceNow Engineer" },
    { value: "Project Manager", label: "Project Manager" },
    {
      value: "Veeva Engineer (all roles)",
      label: "Veeva Engineer (all roles)",
    },
    { value: "SAP Engineer (all roles)", label: "SAP Engineer (all roles)" },
    {
      value: "Blue Yonder Engineer (all roles)",
      label: "Blue Yonder Engineer (all roles)",
    },
    { value: "Senior Network Engineer", label: "Senior Network Engineer" },
    { value: "Accessibility Lead", label: "Accessibility Lead" },
    {
      value: "SAP Business Analyst II - PP/QM/MM",
      label: "SAP Business Analyst II - PP/QM/MM",
    },
    { value: "Splunk Developer", label: "Splunk Developer" },
    {
      value: "Technical Architect - Oracle Revenue Management & Billing",
      label: "Technical Architect - Oracle Revenue Management & Billing",
    },
    { value: "Ui Developer - Front End", label: "Ui Developer - Front End" },
    { value: "Service Desk Analyst", label: "Service Desk Analyst" },
    { value: "SAP IT Architect", label: "SAP IT Architect" },
    {
      value: "MS Access Database Developer",
      label: "MS Access Database Developer",
    },
    { value: "SQL Developer", label: "SQL Developer" },
    {
      value: "Sr. Java/AWS Developer (With Node.js exp)",
      label: "Sr. Java/AWS Developer (With Node.js exp)",
    },
    { value: ".Net Developer", label: ".Net Developer" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Pardot Lead / Specialist - Salesforce Marketing",
      label: "Pardot Lead / Specialist - Salesforce Marketing",
    },
    { value: "Tableau Developer", label: "Tableau Developer" },
    { value: "HR IT Business Analyst", label: "HR IT Business Analyst" },
  ];

  useEffect(() => {
    getUserProfile(currentUser?.userId).then((res) => {
      setGetStatus(res?.data?.Data?.profileStatus || []);
    });
  }, []);
  //STATES
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [, setActiveTypeIndex] = useState(1);
  const [, setActiveGenderIndex] = useState(1);
  const [returningMothersSelected, setReturningMothersSelected] =
    useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState<string>(initialValues.currency);
  const [data] = useState<PersonalDetail>(initialValues);
  const [type, setType] = useState<number>();

  //SIGN OUT
  const handleSignOut = () => {
    localStorage.setItem("Forte", "");
    localStorage.removeItem("Forte");
    removeAuth();
    logout();
  };

  

  //GET COUNTRIES LIST
  useEffect(() => {
    getCountries().then((res: any) => {
      setCountries(res.data.Data);
    });
  }, []);

  //GET COUNTRY TO SELECT
  useEffect(() => {
    const selectedCountryObject = countries.find(
      (country: any) => country.name === initialValues?.country
    );
    if (selectedCountryObject) {
      getStates(selectedCountryObject.id)
        .then((res) => {
          setStates(res.data.Data);
        })
        .catch((error) => {
          setStates(error);
        });
    }
  }, [countries, initialValues?.country]);

  //GET STATES LIST
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryOption = e.target.options[e.target.selectedIndex];
    const newCurrencySymbol =
      selectedCountryOption.getAttribute("data-currency");
    setCurrency(newCurrencySymbol || ""); // Update the currency state
    const selectedCountry =
      e.target.options[e.target.selectedIndex].getAttribute("data-id");
    formik.setFieldValue("country", e.target.value);
    getStates(selectedCountry).then((res: any) => {
      setStates(res.data.Data);
    });
  };

  //GET USER ID FROM SESSION
  const userId = currentUser?.userId;

  const formik = useFormik({
    initialValues: {
      userId:'',
      jobTitle: '',
      jobType: '',
      jobRole: '',
      experience: '',
      salary: '',
      hiringTimeline: '',
      hireCount: '',
      country: '',
      location: ''
    },
    validationSchema: personalDetailSchema,
    
    onSubmit: (values) => {
      console.log('values====>userId', values)
      navigate("/employer/add/posting-jobs-details", { state: { employerDetails: values } });
    }
  });


  
  if (loading) {
    if (!(Number(getStatus) >= 0)) {
      return (
        <div>
          <AccessDenied />
        </div>
      );
    }
  }
  //  WorkMode
  const handleCheckboxChange = (value: string) => {
    setCheckedModes((prevCheckedModes) => {
      if (prevCheckedModes.includes(value)) {
        return prevCheckedModes.filter((mode) => mode !== value);
      } else {
        return [...prevCheckedModes, value];
      }
    });

    // Convert array to comma-separated string
    const selectedModesString = checkedModes.includes(value)
      ? checkedModes.filter((mode) => mode !== value).join(",")
      : [...checkedModes, value].join(",");

    // Update the formik values with the converted string
    formik.setFieldValue("jobType", selectedModesString);
  };
  // Role
  const handleCheckboxxChange = (value: string) => {
    setCheckedWorkType((prevCheckedWorkTypes) => {
      if (prevCheckedWorkTypes.includes(value)) {
        return prevCheckedWorkTypes.filter((workType) => workType !== value);
      } else {
        return [...prevCheckedWorkTypes, value];
      }
    });

    // Convert array to comma-separated string
    const selectedWorkTypesString = checkedWorkType.includes(value)
      ? checkedWorkType.filter((workType) => workType !== value).join(",")
      : [...checkedWorkType, value].join(",");

    // Update the formik values with the converted string
    formik.setFieldValue("jobRole", selectedWorkTypesString);
  };

// console.log("formik-----personal addd",formik)
  //DESIGN START
  return (
    <>
      <section className="container-fluid">
        <div className="row add-profile">
          {/* <Menu /> */}
          <div className="col-8 right-wrap">
            <div className="form-wrap">
              <h3 className="mb-md-5 mb-4">Provide Basic Information</h3>
              <form onSubmit={formik.handleSubmit} className="custom-form">
                {formik.status ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                ) : null}
                {/* Job Title */}
                <div className="row" style={{ marginBottom: "3%" }}>
                  <div className="col-12">
                    <label className="form-label">
                      Job Title
                      <span className="astrick">*</span>
                    </label>
                    <Select
                      isMulti={true}
                      options={options}
                      name="jobTitle"
                      value={options.filter(
                        (option) =>
                          formik.values.jobTitle &&
                          formik.values.jobTitle.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValuesString = selectedOptions
                          .map((option) => option.value)
                          .join(",");
                        formik.setFieldValue(
                          "jobTitle",
                          selectedValuesString
                        );

                        // Hide the menu if only one job is selected
                        setIsMenuOpen(selectedOptions.length !== 1);
                      }}
                      placeholder="Select up to 1 jobs"
                      menuIsOpen={isMenuOpen}
                      onFocus={() => setIsMenuOpen(true)} // Open the menu when clicked
                      onBlur={(e) => {
                        // Hide the menu if only one job is selected or the Select loses focus
                        if (
                          formik.values.jobTitle &&
                          formik.values.jobTitle.split(",").length <= 1
                        ) {
                          setIsMenuOpen(false);
                        }
                      }}
                    />
                  </div>

                  {formik.touched.jobTitle && formik.errors.jobTitle && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.jobTitle}</span>
                    </div>
                  )}
                </div>

                {/* Mode */}
                <div className="row mb-4">
                  <div className="col-12 col-xl-5">
                    <label className="form-label mt-1 mb-0">
                      Job Type
                      <span className="astrick">*</span>
                    </label>
                  </div>
                  <div className="col-12 col-xl-7">
                    <div className="row checkbox-wrap mt-2 mt-xl-0">
                      {["Onsite", "Remote", "Hybrid"].map((mode, index) => (
                        <div className="col-md-4" key={index}>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="jobType"
                              id={`jobType${index}`}
                              value={mode}
                              checked={checkedModes.includes(mode)}
                              onChange={() => handleCheckboxChange(mode)}
                            
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobType${index}`}
                            >
                              {mode}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                    {formik.touched.jobType && formik.errors.jobType && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.jobType}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* Role */}
                <div className="row mb-4">
                  <div className="col-12 col-xl-5">
                    <label className="form-label mt-1 mb-0">
                      Job Role
                      <span className="astrick">*</span>
                    </label>
                  </div>
                  <div className="col-12 col-xl-7">
                    <div className="row checkbox-wrap mt-2 mt-xl-0">
                      {["Full Time", "Contract", "Part Time"].map(
                        (jobRole, index) => (
                          <div className="col-md-4" key={index}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                  name="jobRole"
                                id={`jobRole${index}`}
                                checked={checkedWorkType.includes(jobRole)}
                                onChange={() => handleCheckboxxChange(jobRole)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`jobRole${index}`}
                              >
                                {jobRole}
                              </label>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    {formik.touched.jobRole &&
                      formik.errors.jobRole && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{formik.errors.jobRole}</span>
                        </div>
                      )}
                  </div>
                </div>
                {/* Experience */}
                <div className="row mt-4 mb-4">
                  <div className="col-6">
                    <label className="form-label">Experience</label>
                    <span className="astrick">*</span>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Years"
                        {...formik.getFieldProps("experience")}
                      />
                      <span className="input-group-text">Years</span>
                    </div>
                    {formik.touched.experience && formik.errors.experience && (
                            <div className="fv-plugins-message-container">
                                <span role="alert">{formik.errors.experience}</span>
                            </div>
                        )}
                  </div>
                  <div className="col-6">
                    <label className="form-label">Salary</label>
                    <span className="astrick">*</span>
                    <div className="input-group">
                    <span className="input-group-text">
                                {data.currency}
                              </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Salary"
                        {...formik.getFieldProps("salary")}
                      />
                     
                    </div>
                    {formik.touched.salary && formik.errors.salary && (
                            <div className="fv-plugins-message-container">
                                <span role="alert">{formik.errors.salary}</span>
                            </div>
                        )}
                  </div>
                </div>
                {/* Hiring TimeLine */}
                <div className="row mb-md-4 mb-3">
                  <div className="col-md-6">
                    <label className="form-label">
                      Hiring TimeLine<span className="astrick">*</span>
                    </label>
                    <textarea
                      {...formik.getFieldProps("hiringTimeline")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.hiringTimeline && formik.errors.hiringTimeline,
                        },
                        {
                          "is-valid":
                            formik.touched.hiringTimeline &&
                            !formik.errors.hiringTimeline,
                        }
                      )}
                    ></textarea>
                    {formik.touched.hiringTimeline && formik.errors.hiringTimeline && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.hiringTimeline}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Hiring Count<span className="astrick">*</span>
                    </label>
                    <textarea
                      {...formik.getFieldProps("hireCount")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.hireCount && formik.errors.hireCount,
                        },
                        {
                          "is-valid":
                            formik.touched.hireCount &&
                            !formik.errors.hireCount,
                        }
                      )}
                    ></textarea>
                    {formik.touched.hireCount && formik.errors.hireCount && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.hireCount}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* Address */}
                <div className="row mb-md-4 mb-3">
                  <div className="col-md-12">
                    <label className="form-label">
                      Address<span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("location")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.location && formik.errors.location,
                        },
                        {
                          "is-valid":
                            formik.touched.location && !formik.errors.location,
                        }
                      )}
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.location}</span>
                      </div>
                    )}
                  </div>
               
                {/* country */}
                  {/* <div className="col-md-6 mb-3 mb-md-0">
                    <label className="form-label">
                      Country<span className="astrick">*</span>
                    </label>
                    <select
                      {...formik.getFieldProps("country")}
                      onChange={handleCountryChange}
                      className="form-select form-control"
                      aria-label="Default select example"
                    >
                      <option selected value="">
                        Choose...
                      </option>
                      {countries.map((item: any, i: any) => {
                        return (
                          <option
                            key={i}
                            data-currency={item.currencySymbol}
                            data-id={item.id}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.country && formik.errors.country && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.country}</span>
                      </div>
                    )}
                  </div> */}
                  </div>
{/* Button */}
                  <div className="text-end btm-float">
                    <div className="btn-group">
                      {/* <Link
                        style={{ textDecoration: "none", color: "white" }}
                        to="/employer/add/qualification"
                        className="btn btn-asphalt"
                      >
                        Next
                      </Link> */}
                      <button type="submit" className="btn btn-asphalt">
                      Next
                      </button>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { EmployerDetails };
