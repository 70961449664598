import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getCountries, getStates, updatePostingjobs, viewPostingjobs } from "../core/requests";
import { useAuth } from "../../../../auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { EmployerHeader } from "../../../employer/EmployerHeader";
import { PersonalDetail, initialPersonalValues as initialValues } from "../core/model";

// PROFILE SCHEMA
const personalDetailSchema = Yup.object().shape({
  // jobTitle: Yup.string().required("Job Title is required"),
  // jobType: Yup.string().required("Job Type is required"),
  // jobRole: Yup.string().required("Job Role is required"),
  // experience: Yup.number().required("Experience is required").min(0, "Experience must be at least 0 years"),
  // salary: Yup.number().required("Salary is required").min(0, "Salary must be at least 0"),
  // hiringTimeline: Yup.string().required("Hiring Timeline is required"),
  // hireCount: Yup.number().required("Hiring Count is required").min(0, "Hiring Count must be at least 0"),
  // location: Yup.string().required("Location is required"),
  // country: Yup.string().required("Country is required"),
});

const EditPostingJobsTwo: FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<PersonalDetail>(initialValues);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    viewPostingjobs(id).then((res) => {
      if (res?.Data) {
        setData(res.Data);
      }
    });
  }, [id]);

  useEffect(() => {
    formik.setValues(data); // Update formik's values when `data` changes
  }, [data]);

  const formik = useFormik<PersonalDetail>({
    initialValues: initialValues, // Use initialValues here
    validationSchema: personalDetailSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const updatedData = { ...data, ...values };
      setLoading(true);
      try {
        const res = await updatePostingjobs(id, updatedData);
        if (res.data?.Code === "1") { 
          navigate(`/posting-jobs`);
        } else {
          navigate(`/employer/edit/posting-jobs-details/${id}`);
        }
      } catch (error) {
        console.error("Edit Personal Detail Error:", error);
        navigate(`/employer/edit/posting-jobs-details/${id}`, { replace: true });
      } finally {
        setLoading(false);
      }
    },
  });


  return (
    <>
      <EmployerHeader />
      <section className="container-fluid">
        <div className="row add-profile">
          <div className="col-8 right-wrap">
            <div className="form-wrap">
              <h3 className="mb-md-5 mb-4">Work Experience Details</h3>
              <form onSubmit={formik.handleSubmit} className="custom-form">
                {formik.status ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                ) : null}
                <div className="row mb-md-4 mb-3">
                  <div className="col-md-6">
                    <label className="form-label">
                      Qualification <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("qualification")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.qualification &&
                            formik.errors.qualification,
                        },
                        {
                          "is-valid":
                            formik.touched.qualification &&
                            !formik.errors.qualification,
                        }
                      )}
                    />
                    {formik.touched.qualification &&
                      formik.errors.qualification && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">
                            {formik.errors.qualification}
                          </span>
                        </div>
                      )}
                  </div>

                  {/* Working Hours  */}
                  <div className="col-md-6">
                    <label className="form-label">
                      Working Hours <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("workingHours")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.workingHours &&
                            formik.errors.workingHours,
                        },
                        {
                          "is-valid":
                            formik.touched.workingHours &&
                            !formik.errors.workingHours,
                        }
                      )}
                    />
                    {formik.touched.workingHours &&
                      formik.errors.workingHours && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{formik.errors.workingHours}</span>
                        </div>
                      )}
                  </div>
                </div>
                {/* Technology */}
                <div className="row mb-md-4 mb-3">
                  <div className="col-md-12">
                    <label className="form-label">
                      Technology<span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("technology")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.technology &&
                            formik.errors.technology,
                        },
                        {
                          "is-valid":
                            formik.touched.technology &&
                            !formik.errors.technology,
                        }
                      )}
                    />
                    {formik.touched.technology && formik.errors.technology && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.technology}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* Benifits  */}
                <div className="row mb-md-4 mb-0">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="form-label">
                      Benifits
                      <span className="astrick">*</span>
                    </label>
                    <div className="strengths-tags mt-2 mb-2">
                      {formik.values.benefits &&
                        formik.values.benefits
                          .split(",")
                          .map((strength, index) => (
                            <span
                              key={index}
                              className="badge bg-success me-2"
                              style={{
                                paddingBottom: "7px",
                                paddingTop: "4px",
                              }}
                            >
                              {strength.trim()}
                              <button
                                type="button"
                                className="btn-close btn-close-white ms-1"
                                // onClick={() => handleRemoveJobdesc(index)}
                              ></button>
                            </span>
                          ))}
                    </div>
                    <textarea
                      value={formik.values.benefits}
                      // onChange={(e) => handleTagInputChange(e.target.value)}
                      className={clsx(
                        "form-control h-auto",
                        {
                          "is-invalid":
                            formik.touched.benefits &&
                            formik.errors.benefits,
                        },
                        {
                          "is-valid":
                            formik.touched.benefits &&
                            !formik.errors.benefits,
                        }
                      )}
                      cols={30}
                      rows={5}
                    />
                    <p className="text-success">Separate use comma (,)</p>
                    {formik.touched.benefits &&
                      formik.errors.benefits && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{formik.errors.benefits}</span>
                        </div>
                      )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Job Description
                      <span className="astrick">*</span>
                    </label>
                    <div className="values-tags mt-2 mb-2">
                      {formik.values.jobDescription &&
                        formik.values.jobDescription
                          .split(",")
                          .map((value, index) => (
                            <span
                              key={index}
                              className="badge bg-success me-2"
                              style={{
                                paddingBottom: "7px",
                                paddingTop: "4px",
                              }}
                            >
                              {value.trim()}
                              <button
                                type="button"
                                className="btn-close btn-close-white ms-1"
                                // onClick={() => handleRemoveBenifits(index)}
                              ></button>
                            </span>
                          ))}
                    </div>
                    <textarea
                      value={formik.values.jobDescription}
                      // onChange={(e) => handleBenifitsInputChange(e.target.value)}
                      className={clsx(
                        "form-control h-auto",
                        {
                          "is-invalid":
                            formik.touched.jobDescription && formik.errors.jobDescription,
                        },
                        {
                          "is-valid":
                            formik.touched.jobDescription &&
                            !formik.errors.jobDescription,
                        }
                      )}
                      cols={30}
                      rows={5}
                    />
                    <p className="text-success">Separate use comma (,)</p>
                    {formik.touched.jobDescription && formik.errors.jobDescription && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.jobDescription}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-end btm-float">
                  <div className="btn-group">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="btn btn-asphalt"
                      to="/employer/edit/posting-jobs"
                    >
                      Prev
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-asphalt"
                      disabled={formik.isSubmitting || !formik.isValid || loading}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { EditPostingJobsTwo };
