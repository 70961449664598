import React, { FC, useEffect, useState } from "react";
// import { Menu } from "./Menu";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  AddPostingJobs,
  getCountries,
  getStates,
  personalDetail,
} from "../core/requests";
import { useAuth, removeAuth } from "../../../../auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  PersonalDetail,
  initialPersonalValues as initialValues,
} from "../core/model";
import { getUserProfile } from "../../../../auth/core/_requests";
import { AccessDenied } from "./AccessDenied";
// import Select from "react-select/dist/declarations/src/Select";
import Select, { MultiValue, GroupBase } from "react-select";
import { EmployerHeader } from "../../../employer/EmployerHeader";

//PROFILE SCHEMA
const personalDetailSchema = Yup.object().shape({
  // categoryId: Yup.string().required(
  //   "Please select one of the following options: Fresher, Experienced, or Returning Mothers. Category selection is required."
  // ),
  // gender: Yup.string().required("Gender is required"),
  // institution: Yup.string()
  //   .matches(
  //     /^[^\d!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~]*$/,
  //     "Institution should contain only letters"
  //   )
  //   .max(50, "minimum 3 letters")
  //   .max(50, "Maximum 50 letters")
  //   .required("Institution is required"),
  // address: Yup.string().required("Address is required"),
  // aboutUser: Yup.string()
  //   .required("This field is required")
  //   .test(
  //     "word-count",
  //     "Maximum 100 words allowed",
  //     (value) => value.trim().split(/\s+/).length <= 100
  //   ),
  // country: Yup.string().required("Country is required"),
  // state: Yup.string().required("State is required"),
  // city: Yup.string()
  //   .required("City is required")
  //   .matches(/^[^\d]*$/, "Numeric values are not allowed"),
  // pincode: Yup.string()
  //   .required("Pincode is required")
  //   .matches(/^[0-9]+$/, "Pincode must contain only numeric characters"),
  // topStrengths: Yup.string()
  //   .required("Top Strengths is required")
  //   .matches(/^[^\d]*$/, "Numeric values are not allowed"),
  // jobDescription: Yup.string()
  //   .required("Top Values is required")
  //   .matches(/^[^\d]*$/, "Numeric values are not allowed"),
  // linkedInProfile: Yup.string()
  //   .matches(/^https:\/\/www\.linkedin\.[a-z]+\/.*$/, {
  //     message: "Invalid LinkedIn URL. Example: https://www.linkedin.com/",
  //     excludeEmptyString: true,
  //   })
  //   .nullable(),
});

const EmployerExperience = () => {
  //Get Profile Detail
  const [getStatus, setGetStatus] = useState<number>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [checkedModes, setCheckedModes] = useState<string[]>([]);
  const [checkedWorkType, setCheckedWorkType] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    getUserProfile(currentUser?.userId).then((res) => {
      setGetStatus(res?.data?.Data?.profileStatus || []);
    });
  }, []);
  //STATES
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [, setActiveTypeIndex] = useState(1);
  const [, setActiveGenderIndex] = useState(1);
  const [returningMothersSelected, setReturningMothersSelected] =
    useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState<string>(initialValues.currency);
  const [data] = useState<PersonalDetail>(initialValues);
  const [type, setType] = useState<number>();
  const location = useLocation();
  const employerDetails = location.state?.employerDetails;

  //SIGN OUT
  const handleSignOut = () => {
    localStorage.setItem("Forte", "");
    localStorage.removeItem("Forte");
    removeAuth();
    logout();
  };

  //GET COUNTRIES LIST
  useEffect(() => {
    getCountries().then((res: any) => {
      setCountries(res.data.Data);
    });
  }, []);

  //GET COUNTRY TO SELECT
  useEffect(() => {
    const selectedCountryObject = countries.find(
      (country: any) => country.name === initialValues?.country
    );
    if (selectedCountryObject) {
      getStates(selectedCountryObject.id)
        .then((res) => {
          setStates(res.data.Data);
        })
        .catch((error) => {
          setStates(error);
        });
    }
  }, [countries, initialValues?.country]);

  //GET STATES LIST
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryOption = e.target.options[e.target.selectedIndex];
    const newCurrencySymbol =
      selectedCountryOption.getAttribute("data-currency");
    setCurrency(newCurrencySymbol || ""); // Update the currency state
    const selectedCountry =
      e.target.options[e.target.selectedIndex].getAttribute("data-id");
    formik.setFieldValue("country", e.target.value);
    getStates(selectedCountry).then((res: any) => {
      setStates(res.data.Data);
    });
  };

  //GET USER ID FROM SESSION
  const userId = currentUser?.userId;

  const formik = useFormik({
    initialValues: {
      
      qualification: "",
      workingHours: "",
      technology: "",
      benefits: "",
      jobDescription: "",
      ...employerDetails, // Merging employer details with initial form values
    },
    validationSchema: personalDetailSchema,
    onSubmit: async (values) => {
      const updatedData = { ...values, userId: userId, currency };
      setLoading(true);
      try {
        const res = await AddPostingJobs(updatedData);
        if (res?.Code === "1") {
          navigate("/posting-jobs", { replace: true });
        } else {
          navigate("/employer/add/posting-jobs-details", { replace: true });
        }
      } finally {
        setLoading(false);
      }
    },
  });



  if (loading) {
    if (!(Number(getStatus) >= 0)) {
      return (
        <div>
          <AccessDenied />
        </div>
      );
    }
  }


 

  const handleRemoveBenifits = (indexToRemove: any) => {
    const updatedValues = formik.values.jobDescription
      .split(",")
      .filter((_:any, index:any) => index !== indexToRemove)
      .join(",");
    formik.setFieldValue("jobDescription", updatedValues);
  };
  const handleBenifitsInputChange = (inputValue: any) => {
    formik.setFieldValue("jobDescription", inputValue);
  };

  const handleRemoveJobdesc = (indexToRemove: any) => {
    const updatedStrengths = formik.values.benefits
      .split(",")
      .filter((_:any, index:any) => index !== indexToRemove)
      .join(",");

    formik.setFieldValue("benefits", updatedStrengths);
  };

  // Add a function to handle changes in the textarea
  const handleTagInputChange = (inputValue: any) => {
    formik.setFieldValue("benefits", inputValue);
  };

  console.log("formik-----personal addd", formik);
  //DESIGN START
  return (
    <>
      <EmployerHeader />
      <section className="container-fluid">
        <div className="row add-profile">
          {/* <Menu /> */}
          <div className="col-8 right-wrap">
            <div className="form-wrap">
              <h3 className="mb-md-5 mb-4">Work Experience Details</h3>
              <form onSubmit={formik.handleSubmit} className="custom-form">
                {formik.status ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                ) : null}
                <div className="row mb-md-4 mb-3">
                  <div className="col-md-6">
                    <label className="form-label">
                      Qualification <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("qualification")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.qualification &&
                            formik.errors.qualification,
                        },
                        {
                          "is-valid":
                            formik.touched.qualification &&
                            !formik.errors.qualification,
                        }
                      )}
                    />
                    {formik.touched.qualification &&
                      formik.errors.qualification && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">
                            {String(formik.errors.qualification)}
                          </span>
                        </div>
                      )}
                  </div>

                  {/* Working Hours  */}
                  <div className="col-md-6">
                    <label className="form-label">
                      Working Hours <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("workingHours")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.workingHours &&
                            formik.errors.workingHours,
                        },
                        {
                          "is-valid":
                            formik.touched.workingHours &&
                            !formik.errors.workingHours,
                        }
                      )}
                    />
                    {formik.touched.workingHours &&
                      formik.errors.workingHours && (
                        <div className="fv-plugins-message-container">
                          {String(formik.errors.workingHours)}
                        </div>
                      )}
                  </div>
                </div>
                {/* Technology */}
                <div className="row mb-md-4 mb-3">
                  <div className="col-md-12">
                    <label className="form-label">
                      Technology<span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("technology")}
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.technology &&
                            formik.errors.technology,
                        },
                        {
                          "is-valid":
                            formik.touched.technology &&
                            !formik.errors.technology,
                        }
                      )}
                    />
                    {formik.touched.technology && formik.errors.technology && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{String(formik.errors.technology)}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* Benifits  */}
                <div className="row mb-md-4 mb-0">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="form-label">
                     Benifits
                      <span className="astrick">*</span>
                    </label>

                    <div className="strengths-tags mt-2 mb-2">
                      {formik.values.benefits &&
                        formik.values.benefits
                          .split(",")
                          .map((strength:any, index:any) => (
                            <span
                              key={index}
                              className="badge bg-success me-2"
                              style={{
                                paddingBottom: "7px",
                                paddingTop: "4px",
                              }}
                            >
                              {strength.trim()}
                              <button
                                type="button"
                                className="btn-close btn-close-white ms-1"
                                onClick={() => handleRemoveJobdesc(index)}
                              ></button>
                            </span>
                          ))}
                    </div>

                    <textarea
                      value={formik.values.benefits}
                      onChange={(e) => handleTagInputChange(e.target.value)}
                      className={clsx(
                        "form-control h-auto",
                        {
                          "is-invalid":
                            formik.touched.benefits &&
                            formik.errors.benefits,
                        },
                        {
                          "is-valid":
                            formik.touched.benefits &&
                            !formik.errors.benefits,
                        }
                      )}
                      cols={30}
                      rows={5}
                    />
                    <p className="text-success">Separate use comma (,)</p>
                    {formik.touched.benefits &&
                      formik.errors.benefits && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{String(formik.errors.benefits)}</span>
                        </div>
                      )}
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                    Job Description
                      <span className="astrick">*</span>
                    </label>
                    <div className="values-tags mt-2 mb-2">
                      {formik.values.jobDescription &&
                        formik.values.jobDescription
                          .split(",")
                          .map((value:any, index:any) => (
                            <span
                              key={index}
                              className="badge bg-success me-2"
                              style={{
                                paddingBottom: "7px",
                                paddingTop: "4px",
                              }}
                            >
                              {value.trim()}
                              <button
                                type="button"
                                className="btn-close btn-close-white ms-1"
                                onClick={() => handleRemoveBenifits(index)}
                              ></button>
                            </span>
                          ))}
                    </div>
                    <textarea
                      value={formik.values.jobDescription}
                      onChange={(e) => handleBenifitsInputChange(e.target.value)}
                      className={clsx(
                        "form-control h-auto",
                        {
                          "is-invalid":
                            formik.touched.jobDescription && formik.errors.jobDescription,
                        },
                        {
                          "is-valid":
                            formik.touched.jobDescription &&
                            !formik.errors.jobDescription,
                        }
                      )}
                      cols={30}
                      rows={5}
                    />
                    <p className="text-success">Separate use comma (,)</p>
                    {formik.touched.jobDescription && formik.errors.jobDescription && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{String(formik.errors.jobDescription)}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/*  */}
                {/* Button */}
                <div className="text-end btm-float">
                  <div className="btn-group">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to="/employer/add/posting-jobs"
                      className="btn btn-asphalt"
                    >
                   Prev
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-asphalt"
                      //  disabled={formik.isSubmitting || !formik.isValid}
                    >
                     Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { EmployerExperience };
