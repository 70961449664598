import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { savedJobsList } from "./core/requests";
import { EmployerHeader } from "../../employer/EmployerHeader";
import { useAuth } from "../../../auth";

const SavedJobs = () => {
  const [users, setUsers] = useState<any>([]);
  const [sort, setSort] = useState("");
  const { currentUser } = useAuth();
  console.log("SavedJobsusers====>", users);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    const id = { id: currentUser?.userId };
    savedJobsList(id).then((res) => {
      setUsers(res?.data);
    });
  };

  return (
    <>
      <section className="container-fluid">
        {/* <!-- Header Starts here --> */}
        <EmployerHeader />
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-2">
            <div className="container">
              <div className="row">
                <div className="col-5 col-md-6">
                  <h2 className="page-title mb-0">Find Matches</h2>
                  <h4 className="fw-normal mb-0">
                    Search Results {users?.length}
                  </h4>
                </div>
                {/* New Search */}
                <div className="col-8 col-md-2"></div>
                <div className="col-8 col-md-4">
                  <div className="sort-by">
                    <label className="form-label">Search:</label>
                    {/*  */}
                    <div className="search">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="material-symbols-outlined">search</i>
                        </span>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
                {/* End */}
              </div>
              <div className="row find-match-wrap mt-3">
                {/* list */}
                <div className="col-12 col-lg-12 result-listing mt-4 mt-lg-0">
                  {users?.length > 0 ? (
                    <div className="listing-wrap">
                      {users?.map((item: any, i: any) => (
                        <div className="mb-3" key={i}>
                          <div className="candidate-snippet">
                            <div className="head">
                              <div className="avatar-encl">
                                {item?.profile ? (
                                  <div className="avatar-img">
                                    <img
                                      className="img-fluid"
                                      src={item?.profile}
                                      alt="Candidate Name"
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar-img">
                                    <Avatar
                                      className="img-fluid"
                                      name={item?.postingjob.jobTitle}
                                    ></Avatar>
                                  </div>
                                )}
                                <div className="avatar-detail">
                                  <h5 className="mb-1">
                                    {item?.postingjob.jobTitle}
                                  </h5>
                                  <p className="mb-0 fs-13 text-gray">
                                    {item?.skills?.map(
                                      (skill: any, index: any) => (
                                        <span key={index}>
                                          {skill.name}
                                          {index < item.skills.length - 1 &&
                                            ", "}
                                        </span>
                                      )
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="cont">
                              <div className="row">
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Education
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.postingjob.qualification}
                                  </p>
                                </div>
                                {item?.experienceYears === "null" ? (
                                  <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-11 text-gray mb-1">
                                      Fresher
                                    </p>
                                    <p className="fs-13 mb-0">0 Years</p>
                                  </div>
                                ) : (
                                  <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-11 text-gray mb-1">
                                      Experience
                                    </p>
                                    <p className="fs-13 mb-0">
                                      {item?.postingjob.experience} Years
                                    </p>
                                  </div>
                                )}
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Annual Salary
                                  </p>
                                  <p className="fs-13 mb-0">
                                    $ {item?.postingjob.salary}
                                  </p>
                                </div>
                                  {/* Technologies */}
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                  Technologies
                                  </p>
                                  <p className="fs-13 mb-0">
                                     {item?.postingjob.technology}
                                  </p>
                                </div>
                                  {/* Mode of Work */}
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                  Mode of Work
                                  </p>
                                  <p className="fs-13 mb-0">
                                     {item?.postingjob.jobType}
                                  </p>
                                </div>
                                {/* jobRole */}
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                  Job Role
                                  </p>
                                  <p className="fs-13 mb-0">
                                  {item?.postingjob.jobRole}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-info" role="alert">
                      No results found.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export { SavedJobs };
