import { useEffect, useState } from "react";
import { EmployerHeader } from "../../employer/EmployerHeader";
import { saveFavorite } from "./core/requests";
import { useAuth } from "../../../auth";
import { Button } from "react-bootstrap";
import Avatar from "react-avatar";
import { userFavoriteList } from "../../employer/core/request";

const EmployeeJobsList = () => {
  const { currentUser } = useAuth();
  const [favorites, setFavorites] = useState<number[]>([]);
  const [appliedJobs, setAppliedJobs] = useState<number[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const id = { id: currentUser?.userId };
    const res = await userFavoriteList(id);
    setUsers(res?.data);

    const initialFavorites = res?.data
      .filter((item: any) => item.isSaved)
      .map((item: any) => item.postingjobsId);
    const initialAppliedJobs = res?.data
      .filter((item: any) => item.isApplyed)
      .map((item: any) => item.postingjobsId);

    setFavorites(initialFavorites);
    setAppliedJobs(initialAppliedJobs);
  };

  const toggleFavorite = async (jobId: number, isCurrentlyFavorite: boolean) => {
    try {
      const updatedData = {
        userId: currentUser?.userId,
        postingjobsId: jobId,
        isSaved: !isCurrentlyFavorite,
      };
      await saveFavorite(updatedData);
      setFavorites((prev) =>
        !isCurrentlyFavorite ? [...prev, jobId] : prev.filter((id) => id !== jobId)
      );
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  const handleToggle = (jobId: number) => {
    toggleFavorite(jobId, favorites.includes(jobId));
  };

  const handleApply = async (jobId: number, isCurrentlyApplied: boolean) => {
    try {
      const updatedData = {
        userId: currentUser?.userId,
        postingjobsId: jobId,
        isApplyed: !isCurrentlyApplied,
      };
      await saveFavorite(updatedData);
      setAppliedJobs((prev) =>
        !isCurrentlyApplied ? [...prev, jobId] : prev.filter((id) => id !== jobId)
      );
    } catch (error) {
      console.error("Error toggling application status:", error);
    }
  };

  const handleAppliedJobs = (jobId: number) => {
    handleApply(jobId, appliedJobs.includes(jobId));
  };

  return (
    <>
      <section className="container-fluid">
        <EmployerHeader />
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-2">
            <div className="container">
              <div className="row">
                <div className="col-5 col-md-6">
                  <h2 className="page-title mb-0">Find Matches</h2>
                  <h4 className="fw-normal mb-0">Search Results {users?.length}</h4>
                </div>
                <div className="col-6 col-md-4"></div>
              </div>
              <div className="row find-match-wrap mt-3">
                <div className="col-12 col-lg-12 result-listing mt-4 mt-lg-0">
                  {users?.length > 0 ? (
                    <div className="listing-wrap">
                      {users?.map((item: any) => (
                        <div className="mb-3" key={item.postingjobsId}>
                          <div className="candidate-snippet">
                            <div className="head">
                              <div className="avatar-encl">
                                {item?.profile ? (
                                  <div className="avatar-img">
                                    <img className="img-fluid" src={item?.profile} alt="Candidate Name" />
                                  </div>
                                ) : (
                                  <div className="avatar-img">
                                    <Avatar className="img-fluid" name={item?.jobTitle}></Avatar>
                                  </div>
                                )}
                                <div className="avatar-detail">
                                  <h5 className="mb-1">{item?.jobTitle}</h5>
                                  {/* <p className="mb-0 fs-13 text-gray">
                                    {item?.technology}
                                  </p> */}
                                </div>
                              </div>
                              <div className="btn-encl">
                                <button
                                  className="btn p-2"
                                  style={{
                                    fontSize: "1.50rem",
                                    padding: "0.5rem 0.75rem",
                                    color: favorites.includes(item.postingjobsId) ? "red" : "gray",
                                  }}
                                  onClick={() => {
                                    handleToggle(item.postingjobsId);
                                  }}
                                >
                                  {favorites.includes(item.postingjobsId) ? "❤" : "🤍"}
                                </button>
                              </div>
                            </div>
                            <div className="cont">
                              <div className="row">
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Experience</p>
                                  <p className="fs-13 mb-0">{item?.experience} Years</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Salary</p>
                                  <p className="fs-13 mb-0">$ {item?.salary}</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Education</p>
                                  <p className="fs-13 mb-0">{item?.qualification}</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Technology</p>
                                  <p className="fs-13 mb-0">{item?.technology}</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Mode Of Work</p>
                                  <p className="fs-13 mb-0">{item?.jobType}</p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">Job Role</p>
                                  <p className="fs-13 mb-0">{item?.jobRole}</p>
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                  <Button
                                    onClick={() => handleAppliedJobs(item.postingjobsId)}
                                    variant={appliedJobs.includes(item.postingjobsId) ? "success" : "outline-success"}
                                  >
                                    {appliedJobs.includes(item.postingjobsId) ? "Applied" : "Apply"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-info" role="alert">
                      No results found.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export { EmployeeJobsList };
