import { useEffect, useState } from "react";
import { EmployerHeader } from "./EmployerHeader";
import { getUserList } from "../profile/profile-form/core/requests";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

const EmployerDashboard = () => {
  const [users, setUsers] = useState<any>([]);
  const [sort, setSort] = useState("");
  const [preferredJob, setPreferredJob] = useState("");
  const [technology, setTechnologies] = useState("");
  const [experienceYearsMin, setExperienceYearsMin] = useState("");
  const [experienceYearsMax, setExperienceYearsMax] = useState("");
  const [expectedSalaryMin, setExpectedSalaryMin] = useState("");
  const [expectedSalaryMax, setExpectedSalaryMax] = useState("");
  const [visaStatus, setVisaStatus] = useState("");
  const [education, setEducation] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [skills, setSkills] = useState("");
  const [language, setLanguages] = useState("");
  const [workMode, setModeOfWork] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [preferedRole, setPreferredRole] = useState("");
  // console.log("preferedJob", preferedRole);
  // console.log("sort", sort);
  // console.log("technology", technology);
  // console.log("experienceYears",experienceYearsMin,experienceYearsMax)
  // console.log("expectedSalary",expectedSalaryMin,expectedSalaryMax)
  // console.log("visaStatus", visaStatus);
  // console.log("state", state);
  // console.log("city", city);
  // console.log("skills", skills);
  // console.log("languages", language);
  // console.log("modeOfWork", workMode);
  // console.log("gender", gender);
  // console.log("users", users);
  const filterData = {
    sort,
    preferredJob,
    technology,
    experienceYearsRange: experienceYearsMin || experienceYearsMax ? [experienceYearsMin, experienceYearsMax] : null,
    expectedSalaryRange: expectedSalaryMin || expectedSalaryMax ? [expectedSalaryMin, expectedSalaryMax] : null,
    visaStatus,
    education,
    state,
    city,
    skills,
    language,
    workMode,
    gender,
    country,
    preferedRole,
  };
  

  useEffect(() => {
    getUserList(filterData).then((res) => {
      console.log("res?.", res);
      console.log("res?.Data", res?.Data);
      setUsers(res?.data);
    });
  }, []);
  const clickApply = async (e: any) => {
    e.preventDefault();
    try {
      const filteredExperienceRange = experienceYearsMin || experienceYearsMax ? [experienceYearsMin, experienceYearsMax] : null;
      const filteredSalaryRange = expectedSalaryMin || expectedSalaryMax ? [expectedSalaryMin, expectedSalaryMax] : null;
      const filteredData = {
        ...filterData,
        experienceYearsRange: filteredExperienceRange,
        expectedSalaryRange: filteredSalaryRange,
      };
      
      console.log("filterData123====>", filteredData);
      const res = await getUserList(filteredData);
      console.log("res?.Data---applyyyyy", res?.data);
      setUsers(res?.data);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };
 
  const clickClear = () => {
    setSort("");
    setPreferredJob("");
    setTechnologies("");
    setExperienceYearsMin("");
    setExperienceYearsMax("");
    setExpectedSalaryMin("");
    setExpectedSalaryMax("");
    setVisaStatus("");
    setEducation("");
    setState("");
    setCity("");
    setSkills("");
    setLanguages("");
    setModeOfWork("");
    setGender("");
    setCountry("");
    setPreferredRole("");
  };
  
  return (
    <>
      <section className="container-fluid">
        {/* <!-- Header Starts here --> */}
        <EmployerHeader />
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-2">
            <div className="container">
              <div className="row">
                <div className="col-5 col-md-6">
                  <h2 className="page-title mb-0">Find Matches</h2>
                  <h4 className="fw-normal mb-0">
                    Search Results {users?.length}
                  </h4>
                </div>
                <div className="col-0 col-md-4"></div>
                <div className="col-8 col-md-2">
                  <div className="sort-by">
                    <label className="form-label">Sort by:</label>
                    <select
                      className="form-select"
                      value={sort}
                      onChange={(event) => setSort(event.target.value)}
                    >
                      <option value="latest">Latest</option>
                      <option value="oldest">Oldest</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row find-match-wrap mt-3">
                <div className="col-4 col-lg-4">
                  <div className="left-search-wrap">
                    <div className="form-wrap">
                      <form action="#" className="custom-form">
                        <div className="mb-3">
                          <label className="form-label">Job Title</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={preferredJob}
                            onChange={(e) => setPreferredJob(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Skills</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={skills}
                            onChange={(e) => setSkills(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Technologies</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={technology}
                            onChange={(e) => setTechnologies(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Experience <small>(in years)</small>
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Min"
                              value={experienceYearsMin}
                              onChange={(e) =>
                                setExperienceYearsMin(e.target.value)
                              }
                            />
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Max"
                              value={experienceYearsMax}
                              onChange={(e) =>
                                setExperienceYearsMax(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Expected Annual Salary
                          </label>
                          <div className="input-group two-input-ico">
                            <span className="input-group-text">$</span>
                            <input
                              type="number"
                              className="form-control ps-0"
                              placeholder="Min"
                              value={expectedSalaryMin}
                              onChange={(e) =>
                                setExpectedSalaryMin(e.target.value)
                              }
                            />
                            <span className="input-group-text">$</span>
                            <input
                              type="number"
                              className="form-control ps-0"
                              placeholder="Max"
                              value={expectedSalaryMax}
                              onChange={(e) =>
                                setExpectedSalaryMax(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Education</label>
                          <input
                            type="text"
                            className="form-control"
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Gender</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="1"
                                  checked={gender === "1"}
                                  onChange={(e) =>
                                    setGender(e.target.checked ? "1" : "")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline  me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox2"
                                  value="2"
                                  checked={gender === "2"}
                                  onChange={(e) =>
                                    setGender(e.target.checked ? "2" : "")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox3"
                                  value="3"
                                  checked={gender === "3"}
                                  onChange={(e) =>
                                    setGender(e.target.checked ? "3" : "")
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox3"
                                >
                                  No Preference
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label"> Visa Status</label>
                          <select
                            className="form-select form-control"
                            aria-label="VISA Status"
                            value={visaStatus}
                            onChange={(e) => setVisaStatus(e.target.value)}
                          >
                            <option selected value="">
                              Choose...
                            </option>
                            <option value="Not US citizen">US Citizen</option>
                            <option value="Not US citizen">
                              Not US Citizen
                            </option>
                            <option value="Green Card Holder">
                              Green Card Holder
                            </option>
                            <option value="H1B">H1B</option>
                            <option value="H4 EAD">H4 EAD</option>
                            <option value="F1 CPT">F1 CPT</option>
                            <option value="F1 OPT">F1 OPT</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Languages</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={language}
                            onChange={(e) => setLanguages(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Location</label>
                          <textarea
                            className="form-control h-auto"
                            name=""
                            id=""
                            cols={30}
                            rows={4}
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Mode of Work</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mw1"
                                  value="Onsite"
                                  checked={workMode.includes("Onsite")}
                                  onChange={(e) =>
                                    setModeOfWork(
                                      e.target.checked ? e.target.value : ""
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mw1"
                                >
                                  Onsite
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline  me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mw2"
                                  value="Hybrid"
                                  checked={workMode.includes("Hybrid")}
                                  onChange={(e) =>
                                    setModeOfWork(
                                      e.target.checked ? e.target.value : ""
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mw2"
                                >
                                  Hybrid
                                </label>
                              </div>
                              <div className="col-6 col-md-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mw3"
                                  value="Remote"
                                  checked={workMode.includes("Remote")}
                                  onChange={(e) =>
                                    setModeOfWork(
                                      e.target.checked ? e.target.value : ""
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mw3"
                                >
                                  Remote
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Kind of Role</label>
                          <div className="container">
                            <div className="checkbox-wrap row">
                              <div className="col-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="kr1"
                                  value="Full Time"
                                  checked={preferedRole.includes("Full Time")}
                                  onChange={(e) =>
                                    setPreferredRole(
                                      e.target.checked ? e.target.value : ""
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="kr1"
                                >
                                  Full-time
                                </label>
                              </div>
                              <div className="col-4 form-check form-check-inline  me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="kr2"
                                  value="Contract"
                                  checked={preferedRole.includes("Contract")}
                                  onChange={(e) =>
                                    setPreferredRole(
                                      e.target.checked ? e.target.value : ""
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="kr2"
                                >
                                  Contract
                                </label>
                              </div>
                              <div className="col-4 form-check form-check-inline me-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="kr3"
                                  value="Part Time"
                                  checked={preferedRole.includes("Part Time")}
                                  onChange={(e) =>
                                    setPreferredRole(
                                      e.target.checked ? e.target.value : ""
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="kr3"
                                >
                                  Part Time
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="btm-btn">
                        <button
                          className="btn btn-cancel"
                          type="button"
                          onClick={clickClear}
                        >
                          Clear All
                        </button>
                        <button
                          className="btn btn-asphalt"
                          type="submit"
                          onClick={clickApply}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* list */}
                <div className="col-8 col-lg-8 result-listing mt-4 mt-lg-0">
                  {users?.length > 0 ? (
                    <div className="listing-wrap">
                      {users?.map((item: any, i: any) => (
                        <div className="mb-3" key={i}>
                          <div className="candidate-snippet">
                            <div className="head">
                              <div className="avatar-encl">
                                {item?.profile ? (
                                  <div className="avatar-img">
                                    <img
                                      className="img-fluid"
                                      src={item?.profile}
                                      alt="Candidate Name"
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar-img">
                                    <Avatar
                                      className="img-fluid"
                                      name={item?.firstName}
                                    ></Avatar>
                                  </div>
                                )}
                                <div className="avatar-detail">
                                  <h5 className="mb-1">
                                    {item?.firstName + " " + item?.lastName}
                                  </h5>
                                  <p className="mb-0 fs-13 text-gray">
                                    {item?.skills?.map(
                                      (skill: any, index: any) => (
                                        <span key={index}>
                                          {skill.name}
                                          {index < item.skills.length - 1 &&
                                            ", "}
                                        </span>
                                      )
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="btn-encl">
                                <Link
                                  className="btn btn-asphalt btn-gray me-2"
                                  to={`/employee-profile/${item?.id}`}
                                >
                                  View Profile
                                </Link>
                                <a
                                  className="btn btn-asphalt linkedin-btn"
                                  href={item?.linkedInProfile}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="img-fluid me-2"
                                    src="../../../media/images/linkedin-white-ico.svg"
                                    alt="LinkedIn"
                                  />
                                  LinkedIn Profile
                                </a>
                              </div>
                            </div>
                            <div className="cont">
                              <div className="row">
                                {item?.experienceYears === "null" ? (
                                  <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-11 text-gray mb-1">
                                      Fresher
                                    </p>
                                    <p className="fs-13 mb-0">0 Years</p>
                                  </div>
                                ) : (
                                  <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-11 text-gray mb-1">
                                      Experience
                                    </p>
                                    <p className="fs-13 mb-0">
                                      {item?.experienceYears} Years
                                    </p>
                                  </div>
                                )}
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Expected Annual Salary
                                  </p>
                                  <p className="fs-13 mb-0">
                                    $ {item?.expectedSalary}
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Education
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.education &&
                                    item.education.length > 0
                                      ? item?.education[0]?.certificateName
                                      : "-"}
                                  </p>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                  <p className="fs-11 text-gray mb-1">
                                    Visa Status
                                  </p>
                                  <p className="fs-13 mb-0">
                                    {item?.visaStatus
                                      ? "US Citizen"
                                      : "Not US Citizen"}
                                  </p>
                                </div>
                                {item?.technologies &&
                                item.technologies.length > 0 ? (
                                  <div className="col-12">
                                    <p className="fs-11 text-gray mb-1">
                                      Technologies
                                    </p>
                                    {item?.technologies?.map(
                                      (tech: any, i: any) => (
                                        <span className="badge tech" key={i}>
                                          {tech?.name}
                                        </span>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="col-12">
                                    <p className="fs-11 text-gray mb-1">
                                      Technologies
                                    </p>
                                    <span className="">-</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-info" role="alert">
                      No results found.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export { EmployerDashboard };
