import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthPage, Logout, useAuth } from "../auth";
import { ErrorPage } from "../features/ErrorPage";
import App from "../App";
import { PrivateRoutes } from "./PrivateRoutes";
import About from "../pages/About";
import Contact from "../pages/Contact";
import { Registration } from "../auth/component/Registration";
import { OtpVerification } from "../auth/component/employee/OtpVerification";
import { RegisterSuccess } from "../auth/component/RegisterSuccess";
import { EmployerOtpVerification } from "../auth/component/employer/EmployerOtpVerification";
import { ForgetPassword } from "../auth/component/forget-password/ForgetPassword";
import { ForgetPwdOtpVerification } from "../auth/component/forget-password/ForgetPwdOtpVerification";
import { ResetNewPassword } from "../auth/component/forget-password/ResetNewPassword";
import { FPSuccess } from "../auth/component/forget-password/FPSuccess";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";

function AppRoutes() {
  const { currentUser } = useAuth();

  let redirect = "";

  if (Number(currentUser?.profileStatus) === 0) {
    redirect = "/profile/add/personal-detail";
  }
  if (Number(currentUser?.profileStatus) === 1) {
    redirect = "/profile/add/experience";
  }
  if (Number(currentUser?.profileStatus) === 2) {
    redirect = "/profile/add/resume";
  }
  if (Number(currentUser?.profileStatus) === 3) {
    redirect = "/profile/add/visa";
  }
  if (Number(currentUser?.profileStatus) === 4) {
    redirect = "/profile/add/skills";
  }
  if (Number(currentUser?.profileStatus) === 5) {
    redirect = "/profile/add/education";
  }
  if (Number(currentUser?.profileStatus) === 6) {
    redirect = "/profile/add/expectation";
  }
  if (Number(currentUser?.profileStatus) === 7) {
    redirect = "/profile/add/social-media";
  }
  if (Number(currentUser?.profileStatus) === 9) {
    redirect = "/profile/view";
  }
  // if (Number(currentUser?.profileStatus) === 8) {
  //   redirect = "/profile/add/photo";
  // }
  // if (Number(currentUser?.profileStatus) === 10) {
  //   redirect = "/dashboard";
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to={redirect} />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />

              <Route path="signUp" element={<Registration />} />
              <Route path="otp-verification" element={<OtpVerification />} />
              <Route path="forget-password" element={<ForgetPassword />} />
              <Route path="reset-password" element={<ResetNewPassword />} />
              <Route path="reset-success" element={<FPSuccess />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />

              <Route
                path="fp-otp-verification"
                element={<ForgetPwdOtpVerification />}
              />
              <Route
                path="employer-otp-verification"
                element={<EmployerOtpVerification />}
              />
              <Route path="register-success" element={<RegisterSuccess />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
