import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import {
  getCountries,
  viewPostingjobs,
} from "../core/requests";
import { useAuth } from "../../../../auth";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { PersonalDetail, initialPersonalValues as initialValues } from "../core/model";

// PROFILE SCHEMA
const personalDetailSchema = Yup.object().shape({
  // jobTitle: Yup.string().required("Job Title is required"),
  // jobType: Yup.string().required("Job Type is required"),
  // jobRole: Yup.string().required("Job Role is required"),
  // experience: Yup.number()
  //   .required("Experience is required")
  //   .min(0, "Experience must be at least 0 years"),
  // salary: Yup.number()
  //   .required("Salary is required")
  //   .min(0, "Salary must be at least 0"),
  // hiringTimeline: Yup.string().required("Hiring Timeline is required"),
  // hireCount: Yup.number()
  //   .required("Hiring Count is required")
  //   .min(0, "Hiring Count must be at least 0"),
  // location: Yup.string().required("Location is required"),
  // country: Yup.string().required("Country is required"),
});

// type Props = {
//   initialValues: PersonalDetail;
// };

const EditPostingJobsOne = () => {
  const [data, setData] = useState<PersonalDetail>(initialValues);
  const [countries, setCountries] = useState<any>([]);
  const [checkedModes, setCheckedModes] = useState<string[]>([]);
  const [checkedWorkType, setCheckedWorkType] = useState<string[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    viewPostingjobs(id).then((res) => {
      if (res?.Data) {
        const jobData = res.Data;
        setData(jobData);

        // Set initial values for checkboxes
        setCheckedModes((jobData.jobType || "").split(","));
        setCheckedWorkType((jobData.jobRole || "").split(","));
      }
    });
  }, [id]);

  useEffect(() => {
    // Fetch countries list
    getCountries().then((res: any) => {
      setCountries(res.data.Data);
    });
  }, []);

  const formik = useFormik<PersonalDetail>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: personalDetailSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      navigate(`/employer/edit/posting-jobs-details/${id}`, {
        state: { employerDetails: values },
      });
    },
  });

  const handleCheckboxChange = (value: string) => {
    setCheckedModes((prevCheckedModes) => {
      const newModes = prevCheckedModes.includes(value)
        ? prevCheckedModes.filter((mode) => mode !== value)
        : [...prevCheckedModes, value];

      formik.setFieldValue("jobType", newModes.join(","));
      return newModes;
    });
  };

  const handleCheckboxxChange = (value: string) => {
    setCheckedWorkType((prevCheckedWorkType) => {
      const newWorkTypes = prevCheckedWorkType.includes(value)
        ? prevCheckedWorkType.filter((workType) => workType !== value)
        : [...prevCheckedWorkType, value];

      formik.setFieldValue("jobRole", newWorkTypes.join(","));
      return newWorkTypes;
    });
  };


  // Define options for Select
  const options = [
    { value: "Salesforce Administrator", label: "Salesforce Administrator" },
    {
      value: "Salesforce Business Analyst",
      label: "Salesforce Business Analyst",
    },
    { value: "Salesforce Developer", label: "Salesforce Developer" },
    {
      value: "Salesforce Functional Consultant",
      label: "Salesforce Functional Consultant",
    },
    {
      value: "Salesforce Platform Manager",
      label: "Salesforce Platform Manager",
    },
    {
      value: "Salesforce Solution Architect",
      label: "Salesforce Solution Architect",
    },
    {
      value: "Salesforce Technical Architect",
      label: "Salesforce Technical Architect",
    },
    {
      value: "Salesforce Projects Manager",
      label: "Salesforce Project Manager",
    },
    {
      value: "Senior Test Automation Engineer",
      label: "Senior Test Automation Engineer",
    },
    { value: "Senior ABAP Developer", label: "Senior ABAP Developer" },
    { value: "ServiceNow Engineer", label: "ServiceNow Engineer" },
    { value: "Project Manager", label: "Project Manager" },
    {
      value: "Veeva Engineer (all roles)",
      label: "Veeva Engineer (all roles)",
    },
    { value: "SAP Engineer (all roles)", label: "SAP Engineer (all roles)" },
    {
      value: "Blue Yonder Engineer (all roles)",
      label: "Blue Yonder Engineer (all roles)",
    },
    { value: "Senior Network Engineer", label: "Senior Network Engineer" },
    { value: "Accessibility Lead", label: "Accessibility Lead" },
    {
      value: "SAP Business Analyst II - PP/QM/MM",
      label: "SAP Business Analyst II - PP/QM/MM",
    },
    { value: "Splunk Developer", label: "Splunk Developer" },
    {
      value: "Technical Architect - Oracle Revenue Management & Billing",
      label: "Technical Architect - Oracle Revenue Management & Billing",
    },
    { value: "Ui Developer - Front End", label: "Ui Developer - Front End" },
    { value: "Service Desk Analyst", label: "Service Desk Analyst" },
    { value: "SAP IT Architect", label: "SAP IT Architect" },
    {
      value: "MS Access Database Developer",
      label: "MS Access Database Developer",
    },
    { value: "SQL Developer", label: "SQL Developer" },
    {
      value: "Sr. Java/AWS Developer (With Node.js exp)",
      label: "Sr. Java/AWS Developer (With Node.js exp)",
    },
    { value: ".Net Developer", label: ".Net Developer" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Pardot Lead / Specialist - Salesforce Marketing",
      label: "Pardot Lead / Specialist - Salesforce Marketing",
    },
    { value: "Tableau Developer", label: "Tableau Developer" },
    { value: "HR IT Business Analyst", label: "HR IT Business Analyst" },
  ];

  return (
    <section className="container-fluid">
      <div className="row add-profile">
        {/* <Menu /> */}
        <div className="col-8 right-wrap">
          <div className="form-wrap">
            <h3 className="mb-md-5 mb-4">Provide Basic Information</h3>
            <form onSubmit={formik.handleSubmit} className="custom-form">
              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : null}
              {/* Job Title */}
              <div className="row" style={{ marginBottom: "3%" }}>
                <div className="col-12">
                  <label className="form-label">
                    Job Title
                    <span className="astrick">*</span>
                  </label>
                  <Select
                    isMulti={true}
                    options={options}
                    name="jobTitle"
                    value={options.filter((option) =>
                      (formik.values.jobTitle || "")
                        .split(",")
                        .includes(option.value)
                    )}
                    onChange={(selectedOptions) => {
                      const selectedValuesString = selectedOptions
                        .map((option) => option.value)
                        .join(",");
                      formik.setFieldValue("jobTitle", selectedValuesString);

                      setIsMenuOpen(selectedOptions.length !== 1);
                    }}
                    placeholder="Select up to 1 jobs"
                    menuIsOpen={isMenuOpen}
                    onFocus={() => setIsMenuOpen(true)}
                    onBlur={() => {
                      if (
                        (formik.values.jobTitle || "").split(",").length <= 1
                      ) {
                        setIsMenuOpen(false);
                      }
                    }}
                  />
                </div>

                {formik.touched.jobTitle && formik.errors.jobTitle && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.jobTitle}</span>
                  </div>
                )}
              </div>

              {/* Mode */}
              <div className="row mb-4">
                <div className="col-12 col-xl-5">
                  <label className="form-label mt-1 mb-0">
                    Job Type
                    <span className="astrick">*</span>
                  </label>
                </div>
                <div className="col-12 col-xl-7">
                  <div className="row checkbox-wrap mt-2 mt-xl-0">
                    {["Onsite", "Remote", "Hybrid"].map((mode, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="jobType"
                            id={`jobType${index}`}
                            value={mode}
                            checked={checkedModes.includes(mode)}
                            onChange={() => handleCheckboxChange(mode)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`jobType${index}`}
                          >
                            {mode}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  {formik.touched.jobType && formik.errors.jobType && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.jobType}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Role */}
              <div className="row mb-4">
                <div className="col-12 col-xl-5">
                  <label className="form-label mt-1 mb-0">
                    Job Role
                    <span className="astrick">*</span>
                  </label>
                </div>
                <div className="col-12 col-xl-7">
                  <div className="row checkbox-wrap mt-2 mt-xl-0">
                    {["Full Time", "Contract", "Part Time"].map(
                      (jobRole, index) => (
                        <div className="col-md-4" key={index}>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="jobRole"
                              id={`jobRole${index}`}
                              checked={checkedWorkType.includes(jobRole)}
                              onChange={() => handleCheckboxxChange(jobRole)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobRole${index}`}
                            >
                              {jobRole}
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {formik.touched.jobRole && formik.errors.jobRole && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.jobRole}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Experience */}
              <div className="row mt-4 mb-4">
                <div className="col-6">
                  <label className="form-label">Experience</label>
                  <span className="astrick">*</span>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Years"
                      {...formik.getFieldProps("experience")}
                    />
                    <span className="input-group-text">Years</span>
                  </div>
                  {formik.touched.experience && formik.errors.experience && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.experience}</span>
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label className="form-label">Salary</label>
                  <span className="astrick">*</span>
                  <div className="input-group">
                    {/* <span className="input-group-text">{data.currency}</span> */}
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Salary"
                      {...formik.getFieldProps("salary")}
                    />
                  </div>
                  {formik.touched.salary && formik.errors.salary && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.salary}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Hiring TimeLine */}
              <div className="row mb-md-4 mb-3">
                <div className="col-md-6">
                  <label className="form-label">
                    Hiring TimeLine<span className="astrick">*</span>
                  </label>
                  <textarea
                    {...formik.getFieldProps("hiringTimeline")}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.hiringTimeline &&
                          formik.errors.hiringTimeline,
                      },
                      {
                        "is-valid":
                          formik.touched.hiringTimeline &&
                          !formik.errors.hiringTimeline,
                      }
                    )}
                  ></textarea>
                  {formik.touched.hiringTimeline &&
                    formik.errors.hiringTimeline && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.hiringTimeline}</span>
                      </div>
                    )}
                </div>
                <div className="col-md-6">
                  <label className="form-label">
                    Hiring Count<span className="astrick">*</span>
                  </label>
                  <textarea
                    {...formik.getFieldProps("hireCount")}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.hireCount && formik.errors.hireCount,
                      },
                      {
                        "is-valid":
                          formik.touched.hireCount && !formik.errors.hireCount,
                      }
                    )}
                  ></textarea>
                  {formik.touched.hireCount && formik.errors.hireCount && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.hireCount}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Address */}
              <div className="row mb-md-4 mb-3">
                <div className="col-md-12">
                  <label className="form-label">
                    Address<span className="astrick">*</span>
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("location")}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.location && formik.errors.location,
                      },
                      {
                        "is-valid":
                          formik.touched.location && !formik.errors.location,
                      }
                    )}
                  />
                  {formik.touched.location && formik.errors.location && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.location}</span>
                    </div>
                  )}
                </div>

                {/* country */}
                {/* <div className="col-md-6 mb-3 mb-md-0">
                  <label className="form-label">
                    Country<span className="astrick">*</span>
                  </label>
                  <select
                    {...formik.getFieldProps("country")}
                    onChange={handleCountryChange}
                    className="form-select form-control"
                    aria-label="Default select example"
                  >
                    <option value="">Choose...</option>
                    {countries.map((item: any, i: any) => (
                      <option
                        key={i}
                        data-currency={item.currencySymbol}
                        data-id={item.id}
                        value={item.name}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.country}</span>
                    </div>
                  )}
                </div> */}
              </div>
              {/* Button */}
              <div className="text-end btm-float">
                <div className="btn-group">
                  {/* <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="/employer/add/qualification"
                    className="btn btn-asphalt"
                  >
                    Next
                  </Link> */}
                  <button type="submit" className="btn btn-asphalt">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export { EditPostingJobsOne };
