import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faMicroblog,
} from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../auth/component/alert-context";
import { removeAuth, useAuth } from "../../auth";
import { editProfilePhoto, getUserProfile, pursueEmployment } from "./core/request";
import Avatar from "react-avatar";
import { AlertBox } from "../../auth/component/alert-message";
import { AccessDenied } from "../posting-jobs/posting-jobs-form/add-posting-jobs/AccessDenied";
export interface profilePhoto {
  profile: any;
}
export let initialValues: profilePhoto = {
  profile: "",
};

const ViewProfile = () => {
  const { show, message, type } = useContext(AlertContext);
  const { showAlert } = useContext(AlertContext);
  const { currentUser, logout } = useAuth();
  const [profile, setProfile] = useState<any>({ profile: "" });

  // const [profile, setProfile] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShow] = useState(false);
  const [getStatus, setGetStatus] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<profilePhoto>({ profile: "" });
  const [pursuingEmployment, setPursuingEmployment] = useState<boolean>(false);
  const componentRef = useRef();
  const proficiencyOrder = {
    expert: 1,
    advanced: 2,
    intermediate: 3,
    beginner: 4,
  };
  // console.log("profile?.socialmedia", profile?.socialmedia);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    getUserProfile(currentUser?.userId).then((res) => {
      setGetStatus(res?.data?.Data?.profileStatus || []);

      setProfile(res.data.Data);
    });
  }, []);

  if (loading) {
    if (!(Number(getStatus) >= 8)) {
      return (
        <div>
          <AccessDenied />
        </div>
      );
    }
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const dateString = profile?.updatedAt;
  const date = new Date(dateString);
  const lastUpdateDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  let strengthArray = [];
  let valuessArray = [];
  let joblokkingArray = [];
  let workmodeArray = [];
  let preferedRolesArray = [];
  let countriesArray = [];
  if (profile?.topStrengths) {
    var strength = profile?.topStrengths;
    strengthArray = strength.split(",");
  } else {
    strengthArray = [];
  }
  if (profile?.topValues) {
    var valuess = profile?.topValues;
    valuessArray = valuess.split(",");
  } else {
    valuessArray = [];
  }
  if (profile?.countryWorkedFor) {
    var cValue = profile?.countryWorkedFor;
    countriesArray = cValue.split(",");
  } else {
    countriesArray = [];
  }

  if (profile?.preferedJob) {
    var jobb = profile?.preferedJob;
    joblokkingArray = jobb.split(",");
  } else {
    joblokkingArray = [];
  }
  if (profile?.modeOfWork) {
    var workmod = profile?.modeOfWork;
    workmodeArray = workmod.split(",");
  } else {
    workmodeArray = [];
  }
  if (profile?.preferedRole) {
    var prefRole = profile?.preferedRole;
    preferedRolesArray = prefRole.split(",");
  } else {
    preferedRolesArray = [];
  }
  const removeImage = () => {
    setProfile({ ...profile, profile: "" });
  };

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];

      // Update the data state with the selected file
      setData({ ...data, profile: selectedFile });

      // Read the selected file as a URL and set it as the profile image
      const reader = new FileReader();
      reader.onload = () => {
        setProfile({ ...profile, profile: reader.result });
      };
      reader.readAsDataURL(selectedFile);
    } else {
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Create a new FormData object
    const formData = new FormData();

    // Append the profile id to the FormData object
    formData.append("id", profile.id);

    // Check if an image is uploaded
    if (data.profile) {
      // Append the uploaded image to the FormData object
      formData.append("profile", data.profile);
    } else {
      // If no image is selected or if the image is removed, append a placeholder string for the profile
      formData.append("profile", "null"); // Placeholder string representing null
    }

    // Call editProfilePhoto with the FormData object
    editProfilePhoto(formData).then((res) => {
      if (res) {
        showAlert(res?.data?.Message, res?.data?.Code);
        window.location.href = "/profile/view";
      }
    });
  };
  const getIcon = (name: any) => {
    switch (name) {
      case "Facebook":
        return { icon: faFacebook, color: "#3b5998" }; // Facebook blue
      case "Instagram":
        return { icon: faInstagram, color: "#E1306C" }; // Instagram gradient color
      case "Twitter":
        return { icon: faTwitter, color: "#1DA1F2" }; // Twitter blue
      case "Youtube":
        return { icon: faYoutube, color: "#FF0000" }; // YouTube red
      case "Blog":
        return { icon: faMicroblog, color: "#000000" }; // Black for blog
      default:
        return { icon: faCircle, color: "#000000" }; // Default black color for others
    }
  };
  function getMonthName(monthNumber: number): string {
    const months: string[] = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1] || "";
  }

  const onclickResume = () => {
    const link = document.createElement("a");
    link.href = profile?.resume;
    link.download = "fileName";
    link.target = "_blank";
    link.click();
  };

  const handleSignOut = () => {
    localStorage.setItem("Forte", "");
    localStorage.removeItem("Forte");
    removeAuth();
    logout();
  };
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkbox = event.target;
    const pursueValue = checkbox.checked ? true : false;

    // Check if pursuing employment is being turned on
    if (pursueValue) {
      const confirmation = window.confirm("Do you want to pursue employment?");

      if (confirmation) {
        var userId = currentUser?.userId;
        pursueEmployment(userId, pursueValue).then((res: any) => {
          if (res?.data?.Code === "1") {
            showAlert(res?.data?.Message, res?.data?.Code);
            setProfile({ ...profile, pursueEmployment: pursueValue });
          } else {
            showAlert(res?.data?.Message, res?.data?.Code);
          }
        });
      } else {
        // If the user clicks "Cancel" in the confirmation dialog, revert the checkbox state
        setPursuingEmployment(!pursueValue);
      }
    } else {
      // If pursuing employment is being turned off, proceed without confirmation
      var userId = currentUser?.userId;
      pursueEmployment(userId, pursueValue).then((res: any) => {
        if (res?.data?.Code === "1") {
          showAlert(res?.data?.Message, res?.data?.Code);
          setProfile({ ...profile, pursueEmployment: pursueValue });
        } else {
          showAlert(res?.data?.Message, res?.data?.Code);
        }
      });
    }
  };
  const getMaterialSymbol = (name: any) => {
    switch (name) {
      case "Facebook":
        return "facebook"; // Replace with the appropriate Material Symbol for Facebook
      case "Instagram":
        return "instagram"; // Replace with the appropriate Material Symbol for Instagram
      case "Twitter":
        return "twitter"; // Replace with the appropriate Material Symbol for Twitter
      case "Youtube":
        return "youtube"; // Replace with the appropriate Material Symbol for YouTube
      case "Blog":
        return "rss_feed"; // Replace with the appropriate Material Symbol for Blog
      default:
        return "badge"; // Generic icon for 'Others' or unrecognized names
    }
  };

  const handleDownload = () => {
    // Capture the entire page content
    const content = document.documentElement.innerHTML;

    // Create a new window for the PDF
    const newWindow = window.open("", "_blank");

    // Write the page content to the new window
    if (newWindow) {
      newWindow.document.write(content);
      newWindow.document.close();

      // After the document is closed, print it
      newWindow.print();
    } else {
      showAlert("Error occurred while downloading PDF", "error");
    }
  };
  return (
    <>
      <section className="container-fluid">
        {/* <!-- Header Starts here --> */}
        <header className="row">
          <nav className="navbar navbar-expand-md p-0">
            <div className="container-fluid ps-3 ps-md-4 pe-0">
              <div className="nav-brand">
                <img
                  className="img-fluid"
                  src="../../../media/images/logo-white.png"
                  alt="forte"
                />
              </div>
              <div className="hdr-right d-flex">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/profile/view">
                        Home 
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/create-post">
                        Create Post
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className="nav-link" to="/explore-jobs">
                        Explore Jobs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/applied-job">
                        Applied Jobs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/saved-jobs">
                        Saved Jobs
                      </Link>
                    </li>
                  </ul>
                </div>

                <div
                  className={`dropdown profile-menu ${isOpen ? "show" : ""}`}
                >
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded={isOpen ? "true" : "false"}
                    onClick={toggleDropdown}
                  >
                    <div className="img-wrap">
                      {profile?.profile ? (
                        <Avatar
                          src={profile?.profile}
                          name={profile?.firstName}
                          maxInitials={1}
                          size="40"
                          round
                        ></Avatar>
                      ) : (
                        <Avatar
                          name={profile?.firstName}
                          maxInitials={1}
                          size="40"
                          round
                          color="#607458"
                          fgColor="#ffffff"
                        ></Avatar>
                      )}
                    </div>

                    <span>{profile?.firstName + " " + profile?.lastName}</span>
                  </button>

                  <ul
                    style={{ right: "0" }}
                    className={`dropdown-menu dropdown-menu-end ${
                      isOpen ? "show" : ""
                    }`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/profile/edit/basic-detail"
                      >
                        Edit Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/profile/change-password"
                      >
                        Change Password
                      </Link>
                    </li>
                    <li
                      onClick={() => handleSignOut()}
                      style={{ cursor: "pointer" }}
                      className="dropdown-item "
                    >
                      Logout
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {/* <!-- Header Ends here --> */}

        {/* <!-- Content Section Starts here --> */}
        <section className="content-sec row py-4">
          <div className="container-fluid pt-0 pt-md-3">
            {show && (
              <AlertBox
                type={type === "1" ? "success" : "error"}
                message={message}
              />
            )}
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h2 className="page-title mb-0">My Profile</h2>
                  <p className="mb-0">
                    <small className="text-gray">
                      Last updated on {lastUpdateDate}
                    </small>
                  </p>
                </div>
                {/* {profile && profile?.categoryId !== 1 && ( */}
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-between justify-content-md-end mt-3 mt-md-0">
                    {/* PDF download button and toggle switch */}
                    {/* <Button className="mb-0 me-3" onClick={handleDownload}>Download as PDF</Button> */}
                    <p className="mb-0 me-3">Pursuing Employment</p>

                    <div className="toggle d-flex">
                      <input
                        type="checkbox"
                        checked={profile?.pursueEmployment}
                        onChange={handleToggle}
                      />
                      <label className="yes">Yes</label>
                      <label className="no">No</label>
                    </div>
                  </div>
                {/* )} */}

                {/* Pop-up for pursuing employment */}
                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Pursuing Employment</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Do you want to pursue employment?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      No
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() =>
                        handleToggle({
                          target: { checked: true },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                    >
                      Yes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="row profile-content mt-3">
                <div className="col-12 col-lg-8">
                  {/* <!-- Avatar Sec Starts --> */}
                  <div className="snippet">
                    <div className="cont profile-sec">
                      <div className="avatar-sec">
                        <div className="img-wrap">
                          {profile?.profile ? (
                            // <img
                            //   src={profile?.profile}
                            //   alt="Profile Image"
                            //   style={{ objectFit: "contain" }}
                            //   className="w-100"
                            // />
                            <Avatar
                              src={profile?.profile}
                              name={profile?.firstName || ""}
                              maxInitials={1}
                              round
                              color="#607458"
                              fgColor="#1b1b29"
                            />
                          ) : (
                            <Avatar
                              name={profile?.firstName || ""}
                              maxInitials={1}
                              round
                              className="w-100"
                              color="#607458"
                              fgColor="#1b1b29"
                            />
                          )}
                        </div>

                        <div className="avatar-cont">
                          <h2 className="mb-1">
                            {profile?.firstName + " " + profile?.lastName}
                          </h2>

                          <p
                            style={{ textAlign: "justify" }}
                            className="list-inline-item text-gray"
                          >
                            {profile?.aboutUser}
                          </p>
                          <h6>{profile?.institution}</h6>
                          <div className="ico-row mb-3">
                            <div className="ico" onClick={handleShow}>
                              <span className="material-symbols-outlined">
                                stylus
                              </span>
                            </div>
                            <p className="fs-13 mb-0">Change Profile Here</p>
                          </div>
                          {/* Modal */}
                          <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Profile Upload</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <h5 className="mb-4">
                                Upload your Profile Picture
                              </h5>
                              <br />

                              <input
                                type="file"
                                accept="image/*"
                                onChange={imageHandler}
                              />
                              <br />
                              <br />
                              <span className="hint">
                                (Image format should be .jpg, .jpeg, .png |
                                Image size max 2MB)
                              </span>
                              <br />
                              <br />
                              {/* {profile.profile ? ( */}
                                <div className="mt-3">
                                  <img
                                    // src={profile.profile}
                                    alt="Profile"
                                    style={{ width: "100px", height: "100px" }}
                                  />

                                  <button
                                    className="btn circle-ico"
                                    type="button"
                                  >
                                    <span
                                      className="material-symbols-outlined"
                                      onClick={removeImage}
                                    >
                                      delete
                                    </span>
                                  </button>
                                </div>
                              {/* ) : ( */}
                                <div className="mt-3">
                                  <img
                                    src="../../../media/images/blank.png"
                                    alt="Blank Profile"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                </div>
                              {/* )} */}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                className="btn btn-asphalt"
                                variant="secondary"
                                onClick={handleSubmit}
                              >
                                Update
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                      {profile?.linkedInProfile !== null &&
                      profile?.linkedInProfile !== "" ? (
                        <Link
                          className="btn btn-asphalt linkedin-btn mt-3 mt-md-0"
                          to={profile?.linkedInProfile}
                          target="_blank"
                        >
                          <img
                            className="img-fluid me-2"
                            src="../../../media/images/linkedin-white-ico.svg"
                            alt="LinkedIn"
                          />
                          LinkedIn Profile
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <!-- Avatar Sec Ends --> */}
                  {/* <!-- For <991px Starts --> */}
                  {/* <!-- Personal Information Starts --> */}
                  <div className="snippet w-100 mb-4 mt-4 d-inline-block d-lg-none">
                    <div className="head">
                      <h5 className="mb-0">Personal Information</h5>
                      <Link to="/profile/edit/personal-detail">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            badge
                          </span>
                        </div>
                        {/* <p className="fs-13 mb-0">{profile?.userUniqueId} </p> */}
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            {/* {profile?.gender === 1
                              ? "male"
                              : profile?.gender === 2
                              ? "female"
                              : "transgender"} */}
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {/* {" "}
                          {profile?.gender === 1
                            ? "Male"
                            : profile?.gender === 2
                            ? "Female"
                            : profile?.categoryId === 3
                            ? "Returning Mother"
                            : "Others"} */}
                        </p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            domain
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.institution}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            call
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.mobile}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            mail
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.emailId}</p>
                      </div>
                      <div className="ico-row mb-4 align-items-start">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            location_on
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {profile?.address}
                          <br />
                          {profile?.city}.{profile?.state}
                          <br />
                          {profile?.country}-{profile?.pincode}
                        </p>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">My Strengths</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {strengthArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-2">My Values</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {valuessArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Personal Information Ends --> */}
                  {/* <!-- Expectation Starts --> */}
                  <div className="snippet w-100 mb-4 d-inline-block d-lg-none">
                    <div className="head">
                      <h5 className="mb-0">Expectations</h5>
                      <Link to="/profile/edit/expectation">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="expect-salary mb-4">
                        <p className="mb-0">
                          {profile?.isShowCtc && (
                            <small>Expected Annual Salary </small>
                          )}
                        </p>
                        <h5 className="mb-0">
                          {profile?.isShowCtc ? (
                            <>
                              {profile?.currency} {profile?.expectedSalary}
                            </>
                          ) : null}
                        </h5>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">Jobs looking for</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {joblokkingArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Mode of work looking for
                        </p>
                        <ul className="list-inline with-dot">
                          {workmodeArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Kind of roles preferred
                        </p>
                        <ul className="list-inline with-dot">
                          {preferedRolesArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Message to Hiring Manager
                        </p>
                        <p>
                          <small>{profile?.message}</small>
                        </p>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-1">Notice Period</p>
                        <p>
                          <small>{profile?.noticePeriod} Days</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Expectation Ends --> */}
                  {/* <!-- Social Media Starts Mobile responsive --> */}
                  <div className="snippet w-100 mb-4 d-inline-block d-lg-none">
                    <div className="head">
                      <h5 className="mb-0">Social Media</h5>
                      <Link to="/profile/edit/social-media">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="cont-row mb-3 align-items-start">
                        {profile?.socialmedia?.map((item: any, i: any) => {
                          console.log(
                            "item:socialmediasocialmedia --mobile",
                            item
                          );
                          console.log(
                            "item:item.notInterested",
                            item.notInterested
                          );
                          return (
                            <div key={i}>
                              {item.notInterested ? (
                                <>
                                  <p className="fs-13 fw-med mb-1">
                                    Not Interested
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="fs-18 fw-med mb-1">
                                    <FontAwesomeIcon
                                      className="me-2"
                                      icon={getIcon(item.name).icon}
                                      style={{
                                        color: getIcon(item.name).color,
                                      }}
                                    />
                                    <span className="fs-13 fw-med mb-1">
                                      {item.name}
                                    </span>
                                  </p>
                                  <a href={item.link} target="_blank"><span>{item.link}</span></a>

                                </>
                              )}
                              <br />
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <!-- Social Media Ends --> */}

                  {/* <!-- For <991px Ends --> */}
                  {/* <!-- Scrollspy Nav Starts --> */}
                  <nav className="pill-navbar" id="profile-detail">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a className="nav-link active" href="#work-exp-detail">
                          <span className="material-symbols-outlined ico">
                            business_center
                          </span>
                          Work Experience
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#visa-detail">
                          <span className="material-symbols-outlined ico">
                            demography
                          </span>
                          Work Authorization
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#skill-detail">
                          <span className="material-symbols-outlined ico">
                            dashboard
                          </span>
                          Skills
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#edu-detail">
                          <span className="material-symbols-outlined ico">
                            school
                          </span>
                          Education
                        </a>
                      </li>
                    </ul>
                  </nav>
                  {/* <!-- Scrollspy Nav Ends --> */}
                  {/* <!-- Scrollspy Content Starts --> */}
                  <div
                    className="mt-lg-4 mt-0"
                    data-bs-spy="scroll"
                    data-bs-target="#profile-detail"
                    data-bs-offset="0"
                    tabIndex={0}
                  >
                    {/* <!-- Work Experience Starts --> */}
                    <div className="snippet mb-4" id="work-exp-detail">
                      <div className="head">
                        <h5 className="mb-0">Work Experience </h5>
                        {/* <Link to="/profile/edit/experience">Edit</Link> */}
                        {/* {profile.categoryId === 1 ? ( */}
                          {/* <Link to="/profile/edit/fresher">Edit</Link> */}
                        {/* ) : ( */}
                          {/* <Link to="/profile/edit/experience">Edit</Link> */}
                        {/* )} */}
                      </div>
                      <div className="cont">
                        <div className="row">
                          <div className="col-6">
                            {/* {profile?.categoryId === 1 ? ( */}
                              <p className="fs-14 fw-med mb-1">Fresher</p>
                            {/* ) : ( */}
                              <p className="fs-14 fw-med mb-1">
                                Experience in Years
                              </p>
                            {/* )} */}
                            {/* {profile?.categoryId !== 1 && ( */}
                              <p className="mb-4">
                                {profile?.experienceYears +
                                  " Years and " +
                                  profile?.experienceMonths +
                                  " Months "}{" "}
                              </p>
                            {/* )} */}
                            <br />
                            <div className="col-12 col-xl-7">
                              {/* {profile?.categoryId !== 1 ? ( */}
                                <ul className="list-unstyled exp-timeline mb-0">
                                  {profile?.workedorganisations?.map(
                                    (item: any, i: any) => {
                                      let getType1;
                                      if (item?.workType === "1") {
                                        getType1 = "Part Time";
                                      } else if (item?.workType === "2") {
                                        getType1 = "Full Time";
                                      } else {
                                        getType1 = "Contract";
                                      }

                                      return (
                                        <li className="timeline-item" key={i}>
                                          <p className="mb-0 fs-13 fw-med">
                                            {item?.role}
                                          </p>
                                          <p className="mb-0 fw-med">
                                            <small className="text-gray">
                                              {item?.location +
                                                " - " +
                                                getType1}
                                            </small>
                                          </p>
                                          <p className="mb-0 fw-med">
                                            <small className="text-gray">
                                              {item?.startYear +
                                                " , " +
                                                item?.startMonth}{" "}
                                              -{" "}
                                              {item?.isCurrentlyWorking ? (
                                                <p className="fs-13 mb-0">
                                                  Currently Working
                                                </p>
                                              ) : (
                                                <span>
                                                  {!item?.endYear ||
                                                  !item?.endMonth
                                                    ? "- Still Working"
                                                    : `${getMonthName(
                                                        item.endMonth
                                                      )}, ${item.endYear}`}
                                                </span>
                                              )}
                                            </small>
                                          </p>

                                          {/* <p className="mb-0 fw-med">
                                            <small className="text-gray">
                                              {item?.startYear +
                                                " , " +
                                                item?.startMonth}{" "}
                                              -{" "}
                                              {item?.endYear +
                                                " , " +
                                                item?.endMonth}
                                            </small>
                                          </p> */}
                                          <ul
                                            style={{ color: "#939194" }}
                                            className="list-inline with-dot text-gray"
                                          >
                                            <li className="list-inline-item">
                                              {item?.organisation}
                                            </li>
                                            <li className="list-inline-item">
                                              {item?.expYears}
                                            </li>
                                          </ul>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              {/* ) : ( */}
                                <ul className="list-unstyled exp-timeline mb-0">
                                  {profile?.workedorganisations?.map(
                                    (item: any, i: any) => {
                                      return (
                                        <li className="timeline-item" key={i}>
                                          <p className="mb-0 fs-13 fw-med">
                                            {item?.internPeriod} -{" "}
                                            {item?.internLocation}
                                          </p>

                                          <p className="mb-0 fw-med">
                                            <small className="text-gray">
                                              {item?.internRole}
                                            </small>
                                          </p>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              {/* )} */}
                            </div>

                            <div className="col-12 col-xl-7 mb-3">
                              <div className="row">
                                {/* {profile?.categoryId !== 1 && ( */}
                                  <div className="col-12 mb-4">
                                    <p className="fs-13 fw-med mb-1">
                                      Current Annual Salary
                                    </p>
                                    <p className="mb-0">
                                      {profile?.currency}{" "}
                                      {profile?.annualSalary}
                                    </p>
                                  </div>
                                {/* )} */}

                                <div className="col-12">
                                  <div className="head">
                                    <h5 className="mb-0 me-3">Resume</h5>

                                    <Link to="/profile/edit/resume">Edit</Link>
                                  </div>

                                  <div
                                    className="resume-snip"
                                    onClick={onclickResume}
                                  >
                                    <img
                                      className="ico"
                                      src="../../../media/images/pdf-ico.svg"
                                      alt="Resume"
                                    />
                                    my-resume.pdf
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {profile?.referraldetails &&
                            profile.referraldetails.some((item: any) =>
                              Object.values(item).some(
                                (value: any) => value !== ""
                              )
                            ) && (
                              <div className="col-6">
                                <p className="fw-med">Referral Details</p>
                                {profile?.referraldetails?.map(
                                  (item: any, i: any) => {
                                    return (
                                      <div
                                        className="cont-row mb-3 align-items-start"
                                        key={i}
                                      >
                                        <div className="ico-row mb-3">
                                          <div className="ico">
                                            <span className="material-symbols-outlined">
                                              badge
                                            </span>
                                          </div>
                                          <p className="fs-13 mb-0">
                                            {item?.name}
                                          </p>
                                        </div>

                                        <div className="ico-row mb-3">
                                          <div className="ico">
                                            <span className="material-symbols-outlined">
                                              call
                                            </span>
                                          </div>
                                          <p className="fs-13 mb-0">
                                            {" "}
                                            {item?.contactNumber}
                                          </p>
                                        </div>
                                        <div className="ico-row mb-3">
                                          <div className="ico">
                                            <span className="material-symbols-outlined">
                                              mail
                                            </span>
                                          </div>
                                          <p className="fs-13 mb-0">
                                            {" "}
                                            {item?.email}
                                          </p>
                                        </div>
                                        <div className="ico-row mb-3">
                                          <div className="ico">
                                            <span className="material-symbols-outlined">
                                              domain
                                            </span>
                                          </div>
                                          <p className="fs-13 mb-0">
                                            {item?.organisation}
                                          </p>
                                        </div>
                                        <div className="ico-row mb-3">
                                          <div className="ico">
                                            <span className="material-symbols-outlined">
                                              badge
                                            </span>
                                          </div>
                                          <p className="fs-13 mb-0 me-3">
                                            Exp Years : {item?.years}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                        </div>
                        {/* {profile?.categoryId !== 1 && ( */}
                          <div className="row">
                            <div className="col-12">
                              <p className="fw-med">Previous Technologies</p>
                              {profile?.technologies?.map(
                                (item: any, i: any) => {
                                  return (
                                    <div
                                      className="cont-row mb-3 align-items-start"
                                      key={i}
                                    >
                                      <p className="mb-0">
                                        <small className="fw-med">
                                          {item?.name}
                                        </small>
                                      </p>
                                      <p className="mb-0">
                                        <small className="text-gray">
                                          {item?.workedFor} years
                                        </small>
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        {/* )} */}
                        {/* Reference Details  */}
                        {/* {profile?.categoryId !== 1 && ( */}
                        <div className="row"></div>
                        {/* // )} */}
                      </div>
                    </div>
                    {/* <!-- Work Experience Ends --> */}
                    {/* <!-- Visa Detail Starts --> */}
                    <div className="snippet mb-4" id="visa-detail">
                      <div className="head">
                        <h5 className="mb-0">Work Authorization</h5>
                        <Link to="/profile/edit/visa">Edit</Link>
                      </div>
                      <div className="cont">
                        <div className="cont-row row">
                          <div className="col-6 mb-3">
                            <p className="fs-11 text-gray mb-1">VISA Status</p>
                            <p className="fs-13 mb-0">
                              {profile?.authorizedUs === true
                                ? "US Citizen"
                                : "None"}
                            </p>
                          </div>
                          {profile?.authorizedUs === true ? (
                            <div className="col-6 mb-3">
                              <p className="fs-11 text-gray mb-1">
                                Are you ready to work on Forte W2?
                              </p>
                              <p className="fs-13 mb-0">
                                {profile?.workOnFort ? "Yes" : "No"}
                              </p>
                            </div>
                          ) : null}

                          {profile?.authorizedUs === true ? (
                            <div className="col-6">
                              <p className="fs-11 text-gray mb-1">
                                Are you ready to sign NDA & contract to work
                                with Forte?
                              </p>
                              <p className="fs-13 mb-0">
                                {" "}
                                {profile?.contractWithFort ? "Yes" : "No"}
                              </p>
                            </div>
                          ) : null}
                          {profile?.authorizedUs === false ? (
                            <div className="col-6">
                              <p className="fs-11 text-gray mb-1">
                                Which countries would you like to work?
                              </p>
                              <div className="badge-sec d-flex flex-wrap">
                                {countriesArray?.map((item: any, i: any) => {
                                  return (
                                    <span className="chips" key={i}>
                                      {item}
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* <!-- Visa Detail Ends --> */}
                    {/* <!-- Skill Detail Starts --> */}
                    <div className="snippet mb-4" id="skill-detail">
                      <div className="head">
                        <h5 className="mb-0">Skills</h5>
                        <Link to="/profile/edit/skills">Edit</Link>
                      </div>

                      <div className="cont">
                        <div className="cont-row mb-4">
                          <div className="badge-sec d-flex flex-wrap">
                            {profile?.skills?.map((item: any, i: any) => {
                              return (
                                <span className="chips" key={i}>
                                  {item?.name} - {item?.preficiency}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                        <div className="cont-row">
                          <p className="fw-med">Languages</p>
                          <div className="cont-row align-items-start">
                            <div className="row">
                              {profile?.languages?.map((item: any, i: any) => {
                                return (
                                  <div className="col-md-6 mb-3" key={i}>
                                    <p className="fs-13 fw-med mb-1">
                                      {item?.name}
                                    </p>
                                    <ul className="list-inline with-dot text-gray mb-0">
                                      {item?.read ? (
                                        <li className="list-inline-item">
                                          Read
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.write ? (
                                        <li className="list-inline-item">
                                          Write
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      {item?.speak ? (
                                        <li className="list-inline-item">
                                          Speak
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Skill Detail Ends --> */}
                    {/* <!-- Education Detail Starts --> */}
                    <div className="snippet mb-4" id="edu-detail">
                      <div className="head">
                        <h5 className="mb-0">Education</h5>
                        <Link to="/profile/edit/education">Edit</Link>
                      </div>
                      <div className="cont">
                        <div className="cont-row align-items-start">
                          <div className="row">
                            {profile?.education?.map((item: any, i: any) => {
                              var datExp = item?.expiryDate;
                              const dateE = new Date(datExp);
                              const ExpiryeeDat = dateE.toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              );
                              return (
                                <div className="col-md-6 mb-3" key={i}>
                                  <p className="fs-13 fw-med mb-1 me-3">
                                    {item?.certificateName}
                                  </p>
                                  <ul className="list-inline with-dot text-gray mb-0 me-1">
                                    <li className="list-inline-item">
                                      {" "}
                                      {item?.certificateNo}
                                    </li>
                                    <li className="list-inline-item ">
                                      {" "}
                                      {item?.organisation}
                                    </li>
                                    {item?.expiryDate === null ? (
                                      <li className="list-inline-item">
                                        Non Expiring Certificate
                                      </li>
                                    ) : (
                                      <li className="list-inline-item">
                                        Expires on {ExpiryeeDat}
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Education Detail Ends --> */}
                  </div>
                  {/* <!-- Scrollspy Content Ends --> */}
                </div>
                <div className="col-12 col-lg-4 d-none d-lg-inline-block">
                  {/* <!-- Personal Information Starts --> */}
                  <div className="snippet mb-4">
                    <div className="head">
                      <h5 className="mb-0">Personal Information</h5>
                      <Link to="/profile/edit/personal-detail">Edit</Link>
                    </div>

                    <div className="cont">
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            badge
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.userUniqueId} </p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            {profile?.gender === 1
                              ? "male"
                              : profile?.gender === 2
                              ? "female"
                              : "transgender"}
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {/* {" "}
                          {profile?.gender === 1
                            ? "Male"
                            : profile?.gender === 2
                            ? "Female"
                            : profile?.categoryId === 3
                            ? "Returning Mother"
                            : "Others"} */}
                        </p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            domain
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.institution}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            call
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.mobile}</p>
                      </div>
                      <div className="ico-row mb-3">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            mail
                          </span>
                        </div>
                        <p className="fs-13 mb-0">{profile?.emailId}</p>
                      </div>
                      <div className="ico-row mb-4 align-items-start">
                        <div className="ico">
                          <span className="material-symbols-outlined">
                            location_on
                          </span>
                        </div>
                        <p className="fs-13 mb-0">
                          {profile?.address}
                          <br />
                          {profile?.city}.{profile?.state}
                          <br />
                          {profile?.country}-{profile?.pincode}
                        </p>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">My Strengths</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {strengthArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-2">My Values</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {valuessArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Personal Information Ends --> */}
                  {/* <!-- Expectation Starts --> */}
                  <div className="snippet mb-4">
                    <div className="head">
                      <h5 className="mb-0">Expectations</h5>
                      <Link to="/profile/edit/expectation">Edit</Link>
                    </div>
                    <div className="cont">
                      {profile?.isShowCtc ? (
                        <div className="expect-salary mb-4">
                          <>
                            <p className="mb-0">
                              <small>Expected Annual Salary </small>
                            </p>
                            <h5 className="mb-0">
                              {profile?.currency} {profile?.expectedSalary}
                            </h5>
                          </>
                        </div>
                      ) : null}
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-2">Jobs looking for</p>
                        <div className="badge-sec d-flex flex-wrap">
                          {joblokkingArray?.map((item: any, i: any) => {
                            return (
                              <span className="chips" key={i}>
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Mode of work looking for
                        </p>
                        <ul className="list-inline with-dot">
                          {workmodeArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row mb-3 align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Kind of roles preferred
                        </p>
                        <ul className="list-inline with-dot">
                          {preferedRolesArray?.map((item: any, i: any) => {
                            return (
                              <li className="list-inline-item" key={i}>
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-1">
                          Message to Hiring Manager
                        </p>
                        <p>
                          <small>{profile?.message}</small>
                        </p>
                      </div>
                      <div className="cont-row align-items-start">
                        <p className="fs-13 fw-med mb-1">Notice Period</p>
                        <p>
                          <small>{profile?.noticePeriod} Days</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Expectation Ends --> */}
                  {/* <!-- Social Media --> */}
                  <div className="snippet">
                    <div className="head">
                      <h5 className="mb-0">Social Media</h5>
                      <Link to="/profile/edit/social-media">Edit</Link>
                    </div>
                    <div className="cont">
                      <div className="cont-row mb-3 align-items-start">
                        {profile?.socialmedia?.map((item: any, i: any) => (
                          <div key={i}>
                            {item.notInterested === true ? (
                              <p className="fs-13 fw-med mb-1">
                                Not Interested
                              </p>
                            ) : (
                              <>
                                <p className="fs-13 fw-med mb-1">
                                  <FontAwesomeIcon
                                    className="me-2"
                                    icon={getIcon(item.name).icon}
                                    style={{ color: getIcon(item.name).color }}
                                  />
                                  <span className="fs-13 fw-med mb-1">
                                    {item.name}
                                  </span>
                                </p>
                                <a href={item.link} target="_blank"><span>{item.link}</span></a>

                              </>
                            )}
                            <br />
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* <!-- Social Media ---> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Content Section Ends here --> */}
      </section>
      {/* <!-- Main Wrapper Ends here --> */}
      {/* <!-- Footer Starts here --> */}
      <footer className="container-fluid">
        <p className="mb-0">Copyright 2023 © Forte. All right reserved</p>
      </footer>
      {/* <!-- Footer Ends here --> */}
    </>
  );
};

export default ViewProfile;
