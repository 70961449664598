import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ViewProfile from "../employer/ViewEmployer";
import { EmployerDetails } from "./posting-jobs-form/add-posting-jobs/Posting-Jobs-Details";
import { EmployerExperience } from "./posting-jobs-form/add-posting-jobs/Posting-Jobs-Experience";
import { AccessDenied } from "./posting-jobs-form/add-posting-jobs/AccessDenied";
import { EditPostingJobsTwo } from "./posting-jobs-form/edit-posting-jobs/EditPosting-Jobs-Experience";
import { EditPostingJobsOne } from "./posting-jobs-form/edit-posting-jobs/EditPosting-Jobs-Details";
const EmployerProfilePageNew = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/view"
          element={
            <>
              <ViewProfile />
            </>
          }
        />

        <Route
          path="/add/posting-jobs"
          element={
       <EmployerDetails  /> 
          }
        />
        <Route
          path="/add/posting-jobs-details"
          element={
            <>
              <EmployerExperience />
            </>
          }
        />
    
    <Route
          path="/edit/posting-jobs/:id"
          element={
            <>
            
              <EditPostingJobsOne />
            </>
          }
        />
        <Route
          path="/edit/posting-jobs-details/:id"
          element={
            <>
              <EditPostingJobsTwo />
            </>
          }
        />
         {/* new2 */}
         <Route
          path="/add/posting-jobs"
          element={
          <EmployerDetails />
          }
        />
        {/*  */}
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route index element={<Navigate to="view" />} />
      </Route>
    </Routes>
  );
};

export default EmployerProfilePageNew;
