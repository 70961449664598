import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
//ADD CSS
import "../src/app/assets/custom-style.css";
import "../src/app/assets/custom-style.css.map";
import "../src/app/assets/custom-style.scss";
import "../src/app/assets/splash.scss";
import "../src/app/assets/multiple-tag.scss";
import AppRoutes from "./app/routings/AppRoutes";
import { AuthProvider } from "./app/auth";

<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
  crossOrigin="anonymous"
></script>;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// console.warn = () => {};
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  </React.StrictMode>
);
