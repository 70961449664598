import { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  verifyEmail,
  resendOtp,
  EmployerverifyEmail,
  employerresendOtp,
} from "../../core/_requests";
import { AlertContext } from "../alert-context";
import { AlertBox } from "../alert-message"; 
import { replace } from "formik";

const EmployerOtpVerification = () => {
  const { showAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btnstate, setbtnstate] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  //GET EMAIL FROM REGISTER PAGE
  // RETRIEVE THE EMAIL FROM SESSION STORAGE
  var email = sessionStorage.getItem("Eplremail");
console.log("isFormSubmitted",isFormSubmitted);

  const verifyFunction = () => {
    setLoading(true);
    console.log("ressre");
    console.log("emailotpValue==>",email,otpValue);
    if (email && otpValue) {
      console.log("emailotpValue==>",email,otpValue);
      EmployerverifyEmail(email, otpValue).then((res) => {
        console.log("Res===>",res);
        setIsFormSubmitted(true);

   
        if (res?.Code === "1") {
          navigate("/register-success", { replace: true });
        showAlert(res?.Message, res?.data?.Code);

          sessionStorage.removeItem("Eplremail");
        } 
        else {

          navigate("/employer-otp-verification", { replace: true });
        showAlert(res?.Message, res?.Code);

        }
      });
    } else {
      navigate("/signUp", { state: { message: "Please try again" } });
    }
    setLoading(false);
  };
  const resendOtpFunction = () => {
    employerresendOtp(email).then((res) => {
      setIsFormSubmitted(true);
      console.log("Res=====resendOtpFunctionres",res);

      if (res?.data.Code === "1") {
        showAlert(res?.data.Message, res?.data.Code);
      } else {
        showAlert(res?.data.Message, res?.data.Code);
      }
    });
  };
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  // const handleKeyUp = (index: any, event: any) => {
  //   const Optvalue = event.target.value;

  //   const values = inputRefs.current.map((ref: any) => ref.value);
  //   const nonEmptyValues = values.filter((value) => value.trim() !== "");
  //   if (nonEmptyValues.length === 4) {
  //     setbtnstate(false);
  //     const numbers = nonEmptyValues.map((element) => parseInt(element, 10));
  //     const concatenatedString = numbers.join("");
  //     setOtpValue(concatenatedString);
  //   } else {
  //     setbtnstate(true);
  //     setOtpValue("");
  //   }
  //   if (event.keyCode === 8 && event.target.value === "") {
  //     // Backspace key pressed and current input is empty
  //     const previousIndex = index - 1;
  //     if (previousIndex >= 0 && inputRefs.current[previousIndex]) {
  //       inputRefs.current[previousIndex]?.focus();
  //     }
  //   } else if (event.target.value.length === event.currentTarget.maxLength) {
  //     // Next input field focus logic (same as before)
  //     const nextIndex = index + 1;
  //     if (
  //       nextIndex < inputRefs.current.length &&
  //       inputRefs.current[nextIndex]
  //     ) {
  //       inputRefs.current[nextIndex]?.focus();
  //     }
  //   }
  // };
  const handleKeyUp = (index: any, event: any) => {
    const values = otp.slice();
    const nonEmptyValues = values.filter((value) => value.trim() !== "");
    const valuesTar = inputRefs.current.map((ref: any) => ref.value);
    const nonEmptyValuess = valuesTar.filter((value) => value.trim() !== "");
    if (nonEmptyValuess.length === 4) {
      setbtnstate(false);
      const numbers = nonEmptyValuess.map((element) => parseInt(element, 10));
      const concatenatedString = numbers.join("");
      setOtpValue(concatenatedString);
    } else {
      setbtnstate(true);
      setOtpValue("");
    }

    const maxLength = inputRefs.current[index]?.maxLength || 0;

    if (event.keyCode === 8 && event.target.value === "" && index > 0) {
      // Backspace key pressed and current input is empty
      const previousIndex = index - 1;
      if (inputRefs.current[previousIndex]) {
        inputRefs.current[previousIndex]?.focus();
      }
    } else if (event.target.value.length === maxLength) {
      // Next input field focus logic (same as before)
      const nextIndex = index + 1;
      if (
        nextIndex < inputRefs.current.length &&
        inputRefs.current[nextIndex]
      ) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };
  const handlePaste = (event: any) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain");
    const otpDigits = pastedData.trim().slice(0, 4).split("");
    if (otpDigits.length === 4) {
      setbtnstate(false);
      const numbers = otpDigits.map((element: any) => parseInt(element, 10));
      const concatenatedString = numbers.join("");
      setOtpValue(concatenatedString);
    } else {
      setbtnstate(true);
      setOtpValue("");
    }
    // Distribute the OTP digits to the corresponding input boxes
    otpDigits.forEach((digit: any, index: any) => {
      if (
        inputRefs.current &&
        inputRefs.current[index] &&
        inputRefs.current[index]?.maxLength !== undefined
      ) {
        const inputRef = inputRefs.current[index];
        if (inputRef) {
          inputRef.value = digit;
          handleKeyUp(index, { target: inputRef });
        }
      }
    });
  };
  useEffect(() => {
    const sessionValue = sessionStorage.getItem("Eplremail");
console.log("sessionValue,useEffect",sessionValue);
console.log("isFormSubmitted,useEffect",isFormSubmitted);

    if (!sessionValue && !isFormSubmitted) {
      alert("Please sign up to access this page.");
      window.location.href = "/signup"; // Redirect to signup page
    } else {
      setIsInitialLoad(false);
    }
  }, []);
  const { show, message, type } = useContext(AlertContext);
  return (
    <>
      {/* <!-- Main Wrapper Starts here --> */}
      <section className="container-fluid">
        <div className="row">
          {/* <!-- Left Wrap Starts here --> */}
          <div className="col-md-6 left-wrap">
            <div className="top">
              <div className="nav-brand">
                <img
                  className="img-fluid"
                  src="../../../media/images/logo-white.png"
                  alt="Forte"
                />
              </div>
              <h1 className="mb-3 mb-md-4">Welcome to forte!</h1>
              <p>
                To verify your account and ensure the security of your
                information, we require an OTP (One-Time Password) verification.
                Please follow the steps below to complete the verification
              </p>
            </div>
            <img
              className="img-fluid btm-img d-none d-md-inline-block"
              src="../../../media/images/signup-btm-img.svg"
              alt="Signup"
            />
          </div>
          {/* <!-- Left Wrap Ends here --> */}
          {/* <!-- Right Wrap Starts here --> */}
          <div className="col-6 right-wrap">
            <div className="form-wrap">
              {show && (
                <AlertBox
                  type={type == "1" ? "success" : "error"}
                  message={message}
                />
              )}
              <div className="otp-container text-center">
                <img
                  className="img-fluid mb-5 d-none d-md-inline-block"
                  src="../../../media/images/otp-verify.svg"
                  alt="OTPverfication"
                />

                <h2 className="mb-3">Verfication</h2>
                {/* <p className="lead">
                  We have sent a verification code to your <br />
                  registered email address  - {email}
                </p> */}
                  <p className="lead">
                  We have sent a verification code to - {email}
                </p>
              </div>
              <div className="custom-form">
                <div className="my-4 d-flex justify-content-center">
                  <input
                    type="text"
                    className="form-control otp-input"
                    ref={(el) => (inputRefs.current[0] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(0, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control otp-input"
                    ref={(el) => (inputRefs.current[1] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(1, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control otp-input"
                    ref={(el) => (inputRefs.current[2] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(2, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control otp-input"
                    ref={(el) => (inputRefs.current[3] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(3, event)}
                    onPaste={handlePaste}
                  />
                </div>
                <div className="mb-4 text-center">
                  <button
                    onClick={verifyFunction}
                    className="btn btn-asphalt min-w mb-3"
                    disabled={btnstate}
                  >
                    {!loading && (
                      <span className="indicator-label">Verify</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  <p className="mb-0">
                    If you didn’t receive a code?{" "}
                    <a
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={resendOtpFunction}
                    >
                      Resend
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Right Wrap Starts here --> */}
        </div>
      </section>
      {/* <!-- Main Wrapper Ends here --> */}
    </>
  );
};

export { EmployerOtpVerification };
