import React, { useContext, useEffect, useRef, useState } from "react";
// import { Menu } from "../../../employer/add-employer/Menu";
import { EmployerHeader } from "../../../employer/EmployerHeader";

const AccessDenied = () => {
  return (
    <>
      <div>
      <EmployerHeader />
        <section className="container-fluid">
          <div className="row add-profile">
            {/* <Menu /> */}
            <div className="col-8 right-wrap">
              <div className="form-wrap">
                {/* <button
                  // onClick={() => handleSignOut()}
                  type="button"
                  style={{ float: "right", backgroundColor: "#08213a" }}
                  className="btn btn-secondary btn-sm"
                >
                  Go Back
                </button> */}
                <h2
                  style={{ textAlign: "center", color: "red" }}
                  className="mb-md-5 mb-4"
                >
                  Access Denied
                </h2>
                <h5 style={{ textAlign: "center" }}>
                  Access denied. You are not authorized to use this page. Please
                  complete all required steps.
                </h5>
                <div className="col-8" style={{ margin: "0px auto" }}>
                  <img
                    src="../../../../../media/images/access-denied.png"
                    className="mw-100 mh-300px theme-light-show"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export { AccessDenied };
