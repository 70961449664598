import { getToken } from "../../../../auth";

import axios from "axios";
import { PersonalDetail } from "./model";
//BASE_URL
const API_URL = process.env.REACT_APP_SKYX_API_URL;
//API URL
export const POSTINGJOBS_URL = `${API_URL}/postingjobs`;
export const ADDRESS_URL = `${API_URL}/profile`;
export const PERSONAL_DETAIL_URL = `${API_URL}/profile/personalDetails`;
export const EXPERIENCE_DETAIL_URL = `${API_URL}/profile/workExperience`;
export const FRESHER_DETAIL_URL = `${API_URL}/profile/fresher`;
export const RESUME_DETAIL_URL = `${API_URL}/profile/resume`;
export const UPDATE_PROFILE_URL = `${API_URL}/profile/update-profile-photo`;

const getUserList = async ( filterData: any) => {
  console.log("filterData---api - req",filterData);
  
  const response = await axios
    .post(`${API_URL}/employer/list-user-profile`, filterData);
  console.log("response---api - req",response);
  console.log("response.data---api - req",response.data);

  return response.data;
};



//PROFILE PHOTO
export function updatePhoto(data: FormData) {  
  return axios.post(
    `${API_URL}/profile/profilePictureDetails`,

    data,

    getToken()
  );
}
export function editProfilePhoto(data: FormData) {
  console.log("data", data);
  return axios.post(
    // `${API_URL}/profile/profilePictureDetails`,
    `${UPDATE_PROFILE_URL}`,

    data,

    getToken()
  );
}
//PROFILE PHOTO
export function updateSocialMedia(data: any) {
  return axios.post(`${API_URL}/profile/socialMediaDetails`, data, getToken());
}
//Expectation
export function updateExpectation(data: any) {
  return axios.post(
    `${API_URL}/profile/expectationDetails`,

    data,

    getToken()
  );
}
//Education
export function updateEducation(data: any) {
  return axios.post(
    `${API_URL}/profile/educationDetails`,

    data,

    getToken()
  );
}

//GET COUNTRIES
const getCountries = async () => {
  const res = await axios.get(`${ADDRESS_URL}/countries`, getToken());
  return res;
};
//GET COUNTRIES
const getCurrencies = async () => {
  const res = await axios.get(`${ADDRESS_URL}/currency`, getToken());
  return res;
};
//GET STATES
const getStates = async (countryId: any) => {
  const res = await axios.get(`${ADDRESS_URL}/states/${countryId}`, getToken());
  return res;
};
//GET CITIES
const getCities = async (stateId: any) => {
  const res = await axios.get(`${ADDRESS_URL}/cities/${stateId}`, getToken());
  return res;
};

//CREATE PERSONAL DETAIL
const personalDetail = (
  data: PersonalDetail
): Promise<PersonalDetail | undefined> => {
  return axios.post(PERSONAL_DETAIL_URL, data, getToken()).then((response) => {
    return response.data;
  });
};

//CREATE EXPERIENCE DETAIL
const experienceDetail = (data: FormData) => {
  return axios
    .post(EXPERIENCE_DETAIL_URL, data, getToken())
    .then((response) => {
      return response.data;
    });
};
//CREATE EXPERIENCE DETAIL
const fresherDetail = (data: any) => {
console.log("data",data)
  return axios.post(FRESHER_DETAIL_URL, data, getToken()).then((response) => {
    return response.data;
  });
};
// Resume Upload
const resumeUpload = (data: FormData) => {
console.log("resume data",data)
  return axios.post(RESUME_DETAIL_URL, data, getToken()).then((response) => {
    return response.data;
  });
};
//skill
export function updateSkill(data: any) {
  return axios.post(`${API_URL}/profile/skillDetails`, data, getToken());
}
export function updateVisa(data: any) {
  return axios.post(`${API_URL}/profile/visaDetails`, data, getToken());
}

// export function getUserList(sort: any) {
//   console.log("getUserListApi==>",sort);
//   return axios.post(`${API_URL}/employer/list-user-profile`, { sort }, getToken());
// }

//PURSUE EMPLOYMENT
const pursueEmployment = async (userId: any, pursueValue: any) => {
  const res = await axios.post(
    `${API_URL}/profile/pursueEmployment/${userId}/${pursueValue}`,
    "",
    getToken()
  );

  return res;
};

//GET COUNTRIES
const getLanguages = async () => {
  const res = await axios.get(`${ADDRESS_URL}/languages`, getToken());
  return res;
};

const editPersonalDetail = (
  data: PersonalDetail
): Promise<PersonalDetail | undefined> => {
  return axios
    .post(`${API_URL}/profile/editPersonalDetails`, data, getToken())
    .then((response) => {
      return response.data;
    });
};

//CREATE EXPERIENCE DETAIL
const editExperienceDetail = (data: FormData) => {
  return axios
    .post(`${API_URL}/profile/editWorkExperience`, data, getToken())
    .then((response) => {
      return response.data;
    });
};
const editFresher = (data: FormData) => {
  return axios
    .post(`${API_URL}/profile/editFresher`, data, getToken())
    .then((response) => {
      return response.data;
    });
};
export function editVisa(data: any) {
  return axios.post(`${API_URL}/profile/editVisaDetails`, data, getToken());
}

//Skill
export function editSkill(data: any) {
  return axios.post(`${API_URL}/profile/editSkillDetails`, data, getToken());
}

//Edit Education
export function editEducation(data: any) {
  return axios.post(
    `${API_URL}/profile/editEducationDetails`,
    data,
    getToken()
  );
}
//Edit Expectation
export function editExpectation(data: any) {
  return axios.post(
    `${API_URL}/profile/editExpectationDetails`,
    data,
    getToken()
  );
}
//PROFILE PHOTO
export function editProfileDetail(data: FormData) {
  return axios.post(
    `${API_URL}/profile/editProfilePictureDetails`,

    data,

    getToken()
  );
}
//PROFILE PHOTO
export function editSocialMedia(data: any) {
  return axios.post(
    `${API_URL}/profile/editSocialMediaDetails`,

    data,

    getToken()
  );
}
export function updateBasicDetail(id: any, data: any) {
  return axios.post(
    `${API_URL}/profile/update-profile-employee/${id}`,
    data,
    getToken()
  );
}
export function changePassword(id: any, data: any) {
  return axios.post(
    `${API_URL}/profile/change-password/${id}`,
    data,
    getToken()
  );
}

export function sentOtp(data: any) {
  return axios.post(`${ADDRESS_URL}/profile-send-OTP`, data, getToken());
}

export function verifyOtp(data: any) {
  console.log("data", ADDRESS_URL);
  return axios.post(`${ADDRESS_URL}/profile-verify-OTP`, data, getToken());
}

export function resendOtp(data: any) {
  return axios.post(`${ADDRESS_URL}/profile-resend-OTP`, data, getToken());
}
const checkDuplicate = async (emailId: any) => {
  const response = await axios
    .post(`${ADDRESS_URL}/find-duplicate-email`, { emailId }, getToken());
  return response.data;
};

//  New
const AddPostingJobs = (data: any)=> {
  console.log("data---apiiiii--req",data)

  return axios.post(`${POSTINGJOBS_URL}/add`, data, getToken()).then((response) => {
  console.log("response---apiiiii--req",response)
  console.log("response.data---apiiiii--req",response.data)

    return response.data;
  });
};

//VIEW Postingjobs
// const viewPostingjobs=(id: any)=> {
//   return axios.get(`${POSTINGJOBS_URL}/view/${id}`, getToken());
// }

const viewPostingjobs = (id: any) => {
  console.log("*************************************************")
  console.log("id--aapiiii", id)
  console.log("**************************************************")
  return axios.get(`${POSTINGJOBS_URL}/view/${id}`, getToken()).then((response) => {
    console.log("viewPostingjobs--api--respio",response);
    return response.data;
 });
};

// Edit
const updatePostingjobs= (id: any,data:any)=> {
  console.log("postingjobsiddd====>",id);
  return axios.put(`${POSTINGJOBS_URL}/update/${id}`,data, getToken());
}
// delete Postingjobs
const deletePostingjobs = (id: any) => {
  return axios.post(`${POSTINGJOBS_URL}/delete/${id}`, getToken()).then((response) => {
    return response.data;
 });
};
export {
  getCountries, getUserList,
  getStates,
  getCities,
  personalDetail,
  experienceDetail,
  pursueEmployment,
  getLanguages,
  editPersonalDetail,
  editExperienceDetail,
  getCurrencies,
  fresherDetail,
  editFresher, checkDuplicate, resumeUpload,
  // New 
  AddPostingJobs,
  updatePostingjobs,
  deletePostingjobs, 
  viewPostingjobs,
};
